

// 固定帮助文档长链
// 链接1
export const GET_ADMIN = {  
  text: '如何找管理员？',
  link: 'https://cooper.didichuxing.com/knowledge/share/book/V2fuj0ncesoZ/2202804682367'
}
// 链接2
export const ADMIN_AUTH = {
  text: '管理员不会授予权限怎么办？',
  link: 'https://cooper.didichuxing.com/knowledge/share/book/V2fuj0ncesoZ/2203083987400?anchor=54c114cb-49fd-4890-8e1f-fd36ff317f9e'
}
// 链接3
export const ADMIN_AUTH_TARGET = {
  text: '管理员不会授予权限怎么办？',
  link: 'https://cooper.didichuxing.com/knowledge/share/book/V2fuj0ncesoZ/2203083987400?anchor=778368fd-ee20-44da-b6d3-fa519eb78590'
}
// 链接4
export const SHARE_AUTH = {
  text: '分享者不会授予权限怎么办？',
  link: 'https://cooper.didichuxing.com/knowledge/share/book/V2fuj0ncesoZ/2203084020616'
}

export const LINK1 = 'https://cooper.didichuxing.com/knowledge/share/book/V2fuj0ncesoZ/2202804682367'
export const LINK2 = 'https://cooper.didichuxing.com/knowledge/share/book/V2fuj0ncesoZ/2203083987400?anchor=54c114cb-49fd-4890-8e1f-fd36ff317f9e'
export const LINK3 = 'https://cooper.didichuxing.com/knowledge/share/book/V2fuj0ncesoZ/2203083987400?anchor=778368fd-ee20-44da-b6d3-fa519eb78590'
export const LINK4 = 'https://cooper.didichuxing.com/knowledge/share/book/V2fuj0ncesoZ/2203084020616'

export const QUICK_ACCESS_OPT = 'QUICK_ACCESS_OPT'
export const RECENT_LIST_OPT = 'RECENT_LIST_OPT'
export const TEAM_LIST_OPT = 'TEAM_LIST_OPT'
export const SHARE_LIST_OPT = 'SHARE_LIST_OPT'
export const STAR_LIST_OPT = 'STAR_LIST_OPT'
export const PREVIEW_OPT = 'PREVIEW_OPT'


export const MOVE_TXT = {
  [RECENT_LIST_OPT]: {
    'doc': '你没有权限，可 确认 标准咨询管理员授予${下载}权限或自行申请权限',
    'file': '你没有权限，可咨询管理员授予${下载}权限',
    'folder': '你没有权限，可咨询管理员授予${下载}权限',
    'flow': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'mindMap': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'diagrami 标准ng': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限'
  },
  [TEAM_LIST_OPT]: {
    'doc': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'file': '你没有权限，可咨询管理员授予${下载}权限',
    'folder': '你没有权限，可咨询管理员授予${下载}权限',
    'flow': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'mindMap': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'diagraming': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限'
  },
  [QUICK_ACCESS_OPT]: {
    'doc': '你没有权限，可咨询管理员授予${管理员}权限或自行申请权限',
    'file': '你没有权限，可咨询管理员授予${分享}权限',
    'folder': '你没有权限，可咨询管理员授予${分享}权限',
    'flow': '你没有权限，可咨询管理员授予${管理员}权限或自行申请权限',
    'mindMap': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'diagraming': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限'
  },
}

export const MOVE_LINK = {
  [QUICK_ACCESS_OPT]: {
    'doc': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'file': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'folder': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'flow': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'mindMap': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'diagraming': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],'other': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
  },
  [RECENT_LIST_OPT]: {
    'doc': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'file': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'folder': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'flow': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'mindMap': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'diagraming': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'other': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }]
  },
  [TEAM_LIST_OPT]: {
    'doc': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'file': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'folder': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'flow': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'mindMap': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'diagraming': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'other': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }]
  },
}

export const DUP_TXT = {
  [RECENT_LIST_OPT]: {
    'doc': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'file': '你没有权限，可咨询管理员授予${下载}权限',
    'folder': '你没有权限，可咨询管理员授予${下载}权限',
    'flow': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'mindMap': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'diagraming': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限'
  },
  [TEAM_LIST_OPT]: {
    'doc': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'file': '你没有权限，可咨询管理员授予${下载}权限',
    'folder': '你没有权限，可咨询管理员授予${下载}权限',
    'flow': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'mindMap': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'diagraming': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限'
  },
  [SHARE_LIST_OPT]: {
    'doc': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'file': '你没有权限，可咨询管理员授予${下载}权限',
    'folder': '你没有权限，可咨询管理员授予${下载}权限',
    'flow': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'mindMap': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'diagraming': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限'
  },
  [STAR_LIST_OPT]: {
    'doc': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'file': '你没有权限，可咨询管理员授予${下载}权限',
    'folder': '你没有权限，可咨询管理员授予${下载}权限',
    'flow': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'mindMap': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'diagraming': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限'
  },
}

export const DUP_LINK = {
  [QUICK_ACCESS_OPT]: {
    'doc': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'file': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'folder': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'flow': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'mindMap': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'diagraming': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'other': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }]
  },
  [RECENT_LIST_OPT]: {
    'doc': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'file': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'folder': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'flow': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'mindMap': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'diagraming': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'other': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }]
  },
  [TEAM_LIST_OPT]: {
    'doc': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'file': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'folder': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'flow': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'mindMap': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'diagraming': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'other': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }]
  },
  [SHARE_LIST_OPT]: {
    'doc': [{  
      text: '分享者不会授予权限怎么办？',
      link: LINK4
    }],
    'file': [{  
      text: '分享者不会授予权限怎么办？',
      link: LINK4
    }],
    'folder': [{  
      text: '分享者不会授予权限怎么办？',
      link: LINK4
    }],
    'flow': [{  
      text: '分享者不会授予权限怎么办？',
      link: LINK4
    }],
    'other':  [{  
      text: '分享者不会授予权限怎么办？',
      link: LINK4
    }],
    'mindMap': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'diagraming': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }]
  },
  [STAR_LIST_OPT]: {
    'doc': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }],
    'file': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }],
    'folder': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }],
    'flow': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }],
    'other': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }],
    'mindMap': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'diagraming': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }]
  },
}

export const COPY_TXT = {
  [RECENT_LIST_OPT]: {
    'doc': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'file': '你没有权限，可咨询管理员授予${下载}权限',
    'folder': '你没有权限，可咨询管理员授予${下载}权限',
    'flow': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'mindMap': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'diagraming': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限'
  },
  [TEAM_LIST_OPT]: {
    'doc': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'file': '你没有权限，可咨询管理员授予${下载}权限',
    'folder': '你没有权限，可咨询管理员授予${下载}权限',
    'flow': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'mindMap': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'diagraming': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限'
  },
  [SHARE_LIST_OPT]: {
    'doc': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'file': '你没有权限，可咨询管理员授予${下载}权限',
    'folder': '你没有权限，可咨询管理员授予${下载}权限',
    'flow': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'mindMap': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'diagraming': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限'
  },
  [STAR_LIST_OPT]: {
    'doc': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'file': '你没有权限，可咨询管理员授予${下载}权限',
    'folder': '你没有权限，可咨询管理员授予${下载}权限',
    'flow': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'mindMap': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'diagraming': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限'
  },
}

export const COPY_LINK = {
  [QUICK_ACCESS_OPT]: {
    'doc': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'file': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'folder': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'flow': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'mindMap': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'diagraming': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'other': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }]
  },
  [RECENT_LIST_OPT]: {
    'doc': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'file': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'folder': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'flow': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'mindMap': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'diagraming': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'other': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }]
  },
  [TEAM_LIST_OPT]: {
    'doc': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'file': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'folder': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'flow': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'mindMap': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'diagraming': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'other': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }]
  },
  [SHARE_LIST_OPT]: {
    'doc': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'file': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'folder': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'flow': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'mindMap': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'diagraming': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'other': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }]
  },
  [STAR_LIST_OPT]: {
    'doc': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }],
    'file': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }],
    'folder': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }],
    'flow': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }],
    'mindMap': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }],
    'diagraming': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }],
    'other': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }]
  },
}

// 由于，UI高亮不同重点文案，故采用正则替换方式
// 下载
export const DOWNLOAD_TXT = {
  [QUICK_ACCESS_OPT]: {
    'doc': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'file': '你没有权限，可咨询管理员授予${下载}权限',
    'folder': '你没有权限，可咨询管理员授予${下载}权限',
    'flow': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'mindMap': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'diagraming': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限'
  },
  [RECENT_LIST_OPT]: {
    'doc': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'file': '你没有权限，可咨询管理员授予${下载}权限',
    'folder': '你没有权限，可咨询管理员授予${下载}权限',
    'flow': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'mindMap': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'diagraming': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限'
  },
  [TEAM_LIST_OPT]: {
    'doc': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'file': '你没有权限，可咨询管理员授予${下载}权限',
    'folder': '你没有权限，可咨询管理员授予${下载}权限',
    'flow': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'mindMap': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'diagraming': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限'
  },
  [SHARE_LIST_OPT]: {
    'doc': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'file': '你没有权限，可咨询管理员授予${下载}权限',
    'folder': '你没有权限，可咨询管理员授予${下载}权限',
    'flow': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'mindMap': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'diagraming': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限'
  },
  [STAR_LIST_OPT]: {
    'doc': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'file': '你没有权限，可咨询管理员授予${下载}权限',
    'folder': '你没有权限，可咨询管理员授予${下载}权限',
    'flow': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'mindMap': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限',
    'diagraming': '你没有权限，可咨询管理员授予${下载}权限或自行申请权限'
  },
  // [PREVIEW_OPT]: {
  //   'file': '你没有权限，可咨询管理员授予${下载}权限',
  //   'folder': '你没有权限，可咨询管理员授予${下载}权限',
  // },
}

export const DOWNLOAD_LINK = {
  [QUICK_ACCESS_OPT]: {
    'doc': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'file': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'folder': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'flow': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'mindMap': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'diagraming': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'other': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }]
  },
  [RECENT_LIST_OPT]: {
    'doc': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'file': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'folder': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'flow': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'mindMap': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'diagraming': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'other': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }]
  },
  [TEAM_LIST_OPT]: {
    'doc': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'file': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'folder': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'flow': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'mindMap': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'diagraming': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'other': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }]
  },
  [SHARE_LIST_OPT]: {
    'doc': [{  
      text: '分享者不会授予权限怎么办？',
      link: LINK4
    }],
    'file': [{  
      text: '分享者不会授予权限怎么办？',
      link: LINK4
    }],
    'folder': [{  
      text: '分享者不会授予权限怎么办？',
      link: LINK4
    }],
    'flow': [{  
      text: '分享者不会授予权限怎么办？',
      link: LINK4
    }],
    'other':  [{  
      text: '分享者不会授予权限怎么办？',
      link: LINK4
    }],
    'mindMap': [{  
      text: '分享者不会授予权限怎么办？',
      link: LINK4
    }],
    'diagraming': [{  
      text: '分享者不会授予权限怎么办？',
      link: LINK4
    }]
  },
  [STAR_LIST_OPT]: {
    'doc': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }],
    'file': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }],
    'folder': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }],
    'flow': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }],
    'other': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }],
    'mindMap': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }],
    'diagraming': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }]
  },
}


// 分享
export const SHARE_TXT = {
  [QUICK_ACCESS_OPT]: {
    'doc': '你没有权限，可咨询管理员授予${管理员}权限或自行申请权限',
    'file': '你没有权限，可咨询管理员授予${分享}权限',
    'folder': '你没有权限，可咨询管理员授予${分享}权限',
    'flow': '你没有权限，可咨询管理员授予${管理员}权限或自行申请权限',
    'mindMap': '你没有权限，可咨询管理员授予${管理员}权限或自行申请权限',
    'diagraming': '你没有权限，可咨询管理员授予${管理员}权限或自行申请权限'
  },
  [RECENT_LIST_OPT]: {
    'doc': '你没有权限，可咨询管理员授予${管理员}权限或自行申请权限',
    'file': '你没有权限，可咨询管理员授予${分享}权限',
    'folder': '你没有权限，可咨询管理员授予${分享}权限',
    'flow': '你没有权限，可咨询管理员授予${管理员}权限或自行申请权限',
    'mindMap': '你没有权限，可咨询管理员授予${管理员}权限或自行申请权限',
    'diagraming': '你没有权限，可咨询管理员授予${管理员}权限或自行申请权限'
  },
  [TEAM_LIST_OPT]: {
    'doc': '你没有权限，可咨询管理员授予${管理员}权限或自行申请权限',
    'file': '你没有权限，可咨询管理员授予${分享}权限',
    'folder': '你没有权限，可咨询管理员授予${分享}权限',
    'flow': '你没有权限，可咨询管理员授予${管理员}权限或自行申请权限',
    'mindMap': '你没有权限，可咨询管理员授予${管理员}权限或自行申请权限',
    'diagraming': '你没有权限，可咨询管理员授予${管理员}权限或自行申请权限'
  },
  [STAR_LIST_OPT]: {
    'doc': '你没有权限，可咨询管理员授予${管理员}权限或自行申请权限',
    'file': '你没有权限，可咨询管理员授予${分享}权限',
    'folder': '你没有权限，可咨询管理员授予${分享}权限',
    'flow': '你没有权限，可咨询管理员授予${管理员}权限或自行申请权限',
    'mindMap': '你没有权限，可咨询管理员授予${管理员}权限或自行申请权限',
    'diagraming': '你没有权限，可咨询管理员授予${管理员}权限或自行申请权限'
  },
  // [PREVIEW_OPT]: {
  //   'file': '你没有权限，可咨询管理员授予${分享}权限',
  //   'folder': '你没有权限，可咨询管理员授予${分享}权限',
  // },
}
export const SHARE_LINK = {
  [QUICK_ACCESS_OPT]: {
    'doc': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'file': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'folder': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'flow': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'mindMap': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'diagraming': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'other': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }]
  },
  [RECENT_LIST_OPT]: {
    'doc': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'file': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'folder': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'flow': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'mindMap': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'diagraming': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'other': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }]
  },
  [TEAM_LIST_OPT]: {
    'doc': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'file': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'folder': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'flow': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'mindMap': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'diagraming': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'other': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }]
  },
  [STAR_LIST_OPT]: {
    'doc': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }],
    'file': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }],
    'folder': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }],
    'flow': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }],
    'other': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }],
    'mindMap': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }],
    'diagraming': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }]
  },
}


// 删除
export const DEL_TXT = {
  [QUICK_ACCESS_OPT]: {
    'doc': '你没有权限，可咨询空间/文档所有者授予${文档管理员}权限，或联系文档管理员删除',
    'file': '你没有权限，可咨询空间所有者授予${管理员}权限，或联系文件夹管理员删除',
    'folder': '你没有权限，可咨询空间所有者授予${管理员}权限，或联系文件夹管理员删除',
    'flow': '你没有权限，可咨询空间/文档所有者授予${文档管理员}权限，或联系文档管理员删除',
    'mindMap': '你没有权限，可咨询管理员授予${文档管理员}权限或自行申请权限',
    'diagraming': '你没有权限，可咨询管理员授予${文档管理员}权限或自行申请权限'
  },
  [TEAM_LIST_OPT]: {
    'doc': '你没有权限，可咨询空间/文档所有者授予${文档管理员}权限，或联系文档管理员删除',
    'file': '你没有权限，可咨询空间所有者授予${管理员}权限，或联系文件夹管理员删除',
    'folder': '你没有权限，可咨询空间所有者授予${管理员}权限，或联系文件夹管理员删除',
    'flow': '你没有权限，可咨询空间/文档所有者授予${文档管理员}权限，或联系文档管理员删除',
    'mindMap': '你没有权限，可咨询管理员授予${文档管理员}权限或自行申请权限',
    'diagraming': '你没有权限，可咨询管理员授予${文档管理员}权限或自行申请权限'
  },
  [PREVIEW_OPT]: {
    'file': '你没有权限，可咨询空间所有者授予${管理员}权限，或联系文件夹管理员删除',
    'folder': '你没有权限，可咨询空间所有者授予${管理员}权限，或联系文件夹管理员删除'
  },
}

export const DEL_LINK = {
  [QUICK_ACCESS_OPT]: {
    'doc': [{  
      text: '如何找空间所有者或文档管理员？',
      link: LINK1
    },{  
      text: '空间所有者不会授予权限怎么办？',
      link: LINK2
    }],
    'file': [{  
      text: '如何找空间所有者或文件夹管理员？',
      link: LINK1
    },{  
      text: '空间所有者不会授予权限怎么办？',
      link: LINK2
    }],
    'folder': [{  
      text: '如何找空间所有者或文件夹管理员？',
      link: LINK1
    },{  
      text: '空间所有者不会授予权限怎么办？',
      link: LINK2
    }],
    'flow': [{  
      text: '如何找空间所有者或文档管理员？',
      link: LINK1
    },{  
      text: '空间所有者不会授予权限怎么办？',
      link: LINK2
    }],
    'mindMap': [{  
      text: '如何找空间所有者或文档管理员？',
      link: LINK1
    },{  
      text: '空间所有者不会授予权限怎么办？',
      link: LINK2
    }],
    'diagraming': [{  
      text: '如何找空间所有者或文档管理员？',
      link: LINK1
    },{  
      text: '空间所有者不会授予权限怎么办？',
      link: LINK2
    }],
    'other': [{  
      text: '如何找空间所有者或文档管理员？',
      link: LINK1
    },{  
      text: '空间所有者不会授予权限怎么办？',
      link: LINK2
    }]
  },
  [TEAM_LIST_OPT]: {
    'doc': [{  
      text: '如何找空间所有者或文档管理员？',
      link: LINK1
    },{  
      text: '空间所有者不会授予权限怎么办？',
      link: LINK2
    }],
    'file': [{  
      text: '如何找空间所有者或文件夹管理员？',
      link: LINK1
    },{  
      text: '空间所有者不会授予权限怎么办？',
      link: LINK2
    }],
    'folder': [{  
      text: '如何找空间所有者或文件夹管理员？',
      link: LINK1
    },{  
      text: '空间所有者不会授予权限怎么办？',
      link: LINK2
    }],
    'flow': [{  
      text: '如何找空间所有者或文档管理员？',
      link: LINK1
    },{  
      text: '空间所有者不会授予权限怎么办？',
      link: LINK2
    }],
    'mindMap': [{  
      text: '如何找空间所有者或文档管理员？',
      link: LINK1
    },{  
      text: '空间所有者不会授予权限怎么办？',
      link: LINK2
    }],
    'diagraming': [{  
      text: '如何找空间所有者或文档管理员？',
      link: LINK1
    },{  
      text: '空间所有者不会授予权限怎么办？',
      link: LINK2
    }],
    'other': [{  
      text: '如何找空间所有者或文档管理员？',
      link: LINK1
    },{  
      text: '空间所有者不会授予权限怎么办？',
      link: LINK2
    }]
  },
  [PREVIEW_OPT]: {
    'doc': [{  
      text: '如何找空间所有者或文件夹管理员？',
      link: LINK1
    },{  
      text: '空间所有者不会授予权限怎么办？',
      link: LINK2
    }],
    'file': [{  
      text: '如何找空间所有者或文件夹管理员？',
      link: LINK1
    },{  
      text: '空间所有者不会授予权限怎么办？',
      link: LINK2
    }],
    'folder': [{  
      text: '如何找空间所有者或文件夹管理员？',
      link: LINK1
    },{  
      text: '空间所有者不会授予权限怎么办？',
      link: LINK2
    }],
    'flow': [{  
      text: '如何找空间所有者或文件夹管理员？',
      link: LINK1
    },{  
      text: '空间所有者不会授予权限怎么办？',
      link: LINK2
    }],
    'mindMap': [{  
      text: '如何找空间所有者或文档管理员？',
      link: LINK1
    },{  
      text: '空间所有者不会授予权限怎么办？',
      link: LINK2
    }],
    'diagraming': [{  
      text: '如何找空间所有者或文档管理员？',
      link: LINK1
    },{  
      text: '空间所有者不会授予权限怎么办？',
      link: LINK2
    }],
    'other': [{  
      text: '如何找空间所有者或文档管理员？',
      link: LINK1
    },{  
      text: '空间所有者不会授予权限怎么办？',
      link: LINK2
    }]
  },
}

// 重命名
export const RENAME_TXT = {
  [QUICK_ACCESS_OPT]: {
    'doc': '你没有权限，可咨询管理员授予${编辑}权限或自行申请权限',
    'file': '你没有权限，可咨询管理员授予${编辑}权限',
    'folder': '你没有权限，可咨询管理员授予${编辑}权限',
    'flow': '你没有权限，可咨询管理员授予${编辑}权限或自行申请权限',
    'mindMap': '你没有权限，可咨询管理员授予${编辑}权限或自行申请权限',
    'diagraming': '你没有权限，可咨询管理员授予${编辑}权限或自行申请权限'
  },
  [RECENT_LIST_OPT]: {
    'doc': '你没有权限，可咨询管理员授予${编辑}权限或自行申请权限',
    'file': '你没有权限，可咨询管理员授予${编辑}权限',
    'folder': '你没有权限，可咨询管理员授予${编辑}权限',
    'flow': '你没有权限，可咨询管理员授予${编辑}权限或自行申请权限',
    'mindMap': '你没有权限，可咨询管理员授予${编辑}权限或自行申请权限',
    'diagraming': '你没有权限，可咨询管理员授予${编辑}权限或自行申请权限'
  },
  [TEAM_LIST_OPT]: {
    'doc': '你没有权限，可咨询管理员授予${编辑}权限或自行申请权限',
    'file': '你没有权限，可咨询管理员授予${编辑}权限',
    'folder': '你没有权限，可咨询管理员授予${编辑}权限',
    'flow': '你没有权限，可咨询管理员授予${编辑}权限或自行申请权限',
    'mindMap': '你没有权限，可咨询管理员授予${编辑}权限或自行申请权限',
    'diagraming': '你没有权限，可咨询管理员授予${编辑}权限或自行申请权限'
  },
  [SHARE_LIST_OPT]: {
    'doc': '你没有权限，可咨询管理员授予${编辑}权限或自行申请权限',
    'file': '你没有权限，可咨询管理员授予${编辑}权限',
    'folder': '你没有权限，可咨询管理员授予${编辑}权限',
    'flow': '你没有权限，可咨询管理员授予${编辑}权限或自行申请权限',
    'mindMap': '你没有权限，可咨询管理员授予${编辑}权限或自行申请权限',
    'diagraming': '你没有权限，可咨询管理员授予${编辑}权限或自行申请权限'
  },
  [STAR_LIST_OPT]: {
    'doc': '你没有权限，可咨询管理员授予${编辑}权限或自行申请权限',
    'file': '你没有权限，可咨询管理员授予${编辑}权限',
    'folder': '你没有权限，可咨询管理员授予${编辑}权限',
    'flow': '你没有权限，可咨询管理员授予${编辑}权限或自行申请权限',
    'mindMap': '你没有权限，可咨询管理员授予${编辑}权限或自行申请权限',
    'diagraming': '你没有权限，可咨询管理员授予${编辑}权限或自行申请权限'
  }
}

export const RENAME_LINK = {
  [QUICK_ACCESS_OPT]: {
    'doc': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'file': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'folder': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'flow': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'mindMap': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'diagraming': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'other': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }]
  },
  [RECENT_LIST_OPT]: {
    'doc': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'file': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'folder': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'flow': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'mindMap': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'diagraming': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'other': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }]
  },
  [TEAM_LIST_OPT]: {
    'doc': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'file': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'folder': [{  
      text: '如何找管理员？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'flow': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'mindMap': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'diagraming': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }],
    'other': [{  
      text: '如何找管理员或自行申请权限？',
      link: LINK1
    },{  
      text: '管理员不会授予权限怎么办？',
      link: LINK2
    }]
  },
  [SHARE_LIST_OPT]: {
    'doc': [{  
      text: '分享者不会授予权限怎么办？',
      link: LINK4
    }],
    'file': [{  
      text: '分享者不会授予权限怎么办？',
      link: LINK4
    }],
    'folder': [{  
      text: '分享者不会授予权限怎么办？',
      link: LINK4
    }],
    'flow': [{  
      text: '分享者不会授予权限怎么办？',
      link: LINK4
    }],
    'other': [{  
      text: '分享者不会授予权限怎么办？',
      link: LINK4
    }],
    'mindMap': [{  
      text: '分享者不会授予权限怎么办？',
      link: LINK4
    }],
    'diagraming': [{  
      text: '分享者不会授予权限怎么办？',
      link: LINK4
    }]
  },
  [STAR_LIST_OPT]: {
    'doc': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }],
    'file': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }],
    'folder': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }],
    'flow': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }],
    'other': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }],
    'mindMap': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }],
    'diagraming': [{  
      text: '文件所有者不会授予权限怎么办？',
      link: LINK4
    }]
  }
}

export const PREVIEW_SHARE_LINK = [
  {
    text: '如何找管理员？',
    link: LINK1
  },
  {
    text: '管理员不会授予权限怎么办？',
    link: LINK2
  },
]
