import { intl, getLocale } from 'di18n-react';
import React from 'react';
import { Tooltip } from 'antd';
import onClickOutside from 'react-onclickoutside';
import classNames from 'classnames';
import TetherComponent from 'react-tether';
import { deepCopy } from '@/utils/index';
import { getPermissionOptions } from '@/components/CooperFoldAuth/contants';
import './style.less';

class DropdownCheckbox extends React.Component {
  constructor(props) {
    super(props);
    const options = deepCopy(props.options || []);
    const value = props.value || [];
    options.forEach((item) => {
      item.checked = false;

      if (value.indexOf(item.id) > -1) {
        item.checked = true;
      }
    });
    const values = options.filter((p) => p.checked).map((p) => p.label);
    this.state = {
      text: values.length ? values.join('/') : this.props.placeholder || '',
      options,
      show: false,
      PermissionOptions: getPermissionOptions(),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      String(nextProps.value) !== String(this.props.value)
      || nextProps.placeholder !== this.props.placeholder
    ) {
      const newOptions = deepCopy(nextProps.options);
      const value = nextProps.value || [];
      newOptions.forEach((item) => {
        item.checked = false;

        if (value.indexOf(item.id) > -1) {
          item.checked = true;
        }
      });
      const values = newOptions.filter((p) => p.checked).map((p) => p.label);
      this.setState({
        text: values.length ? values.join('/') : (nextProps.placeholder || ''),
        options: newOptions,
      });
    }
  }

  handleClickOutside = (e) => {
    if (
      e.target.classList.contains('dropdown-checkbox__item')
      || e.target.classList.contains('name')
      || e.target.classList.contains('desc')
    ) {
      return;
    }

    if (!this.hover && this.state.show) {
      this.setState({ show: false });
      if (this.props.handleClickOutsideOnMember) {
        this.props.handleClickOutsideOnMember(this.state.show);
      }
      // 弹窗关闭时，才实际执行权限修改
      if (this.props.onOutsideCloseForChange) {
        let hasChangePerm = false;
        let value = [];
        // 修改权限
        if (this.props.options.length != 0) {
          let newIds = this.state.options.filter((p) => p.checked).map((p) => p.id).join('/');
          let oldIds = this.props.value.join('/');
          hasChangePerm = !(newIds === oldIds);
          const { options } = this.state;
          const newOptions = deepCopy(options).slice();
          newOptions.forEach((p) => {
            if (p.checked) {
              value.push(p.id);
            }
          });
        }
        this.props.onOutsideCloseForChange(value, hasChangePerm);
      }
    }
  }

  handleSelect(item) {
    const { PermissionOptions } = this.state;
    if (this.props.isSpaceMember) {
      window.__OmegaEvent(
        'ep_team_member_modify_ck', '', {
          platform: 'new',
          type: PermissionOptions[item.id].type,
        },
      );
    }
    if (item.disabled) {
      return;
    }

    const { options } = this.state;
    const { onChange = () => {} } = this.props;
    const newOptions = deepCopy(options).slice();
    const value = [];
    newOptions.forEach((p) => {
      if (p.id === item.id) {
        p.checked = !p.checked;
      }

      if (p.checked) {
        value.push(p.id);
      }
    });
    onChange(value, newOptions);
    const values = newOptions.filter((p) => p.checked).map((p) => p.label);
    this.setState({
      options: newOptions,
      text: values.length ? values.join('/') : this.props.placeholder || '',
    });
  }


  handleSelectManage(item) {
    this.setState({
      show: false,
      permChange: true, // 如果设置了管理员等需要立即关闭的，把之前的权限改动都干掉
    });
    item.handle();
  }

  handleClick = () => {
    // 查询恢复/移除权限
    const { onHover = () => { }, changeDisabled, getPermListOnclick } = this.props;
    if (getPermListOnclick && !this.state.show) {
      getPermListOnclick();
    }
    if (changeDisabled) return;
    onHover();
    this.setState({ show: !this.state.show }, () => {
      if (!this.state.show) {
        // 弹窗关闭时，才实际执行权限修改
        if (this.props.onOutsideCloseForChange) {
          let hasChangePerm = false;
          let value = [];
          // 修改权限
          if (this.props.options.length != 0) {
            let newIds = this.state.options.filter((p) => p.checked).map((p) => p.id).join('/');
            let oldIds = this.props.value.join('/');
            hasChangePerm = !(newIds === oldIds);
            const { options } = this.state;
            const newOptions = deepCopy(options).slice();
            newOptions.forEach((p) => {
              if (p.checked) {
                value.push(p.id);
              }
            });
          }
          // 如果选了设置管理员等 就不去请求权限变更的操作了
          this.props.onOutsideCloseForChange(value, this.state.permChange ? false : hasChangePerm);
        }
      }
      if (this.props.handleClickOnMember) {
        this.props.handleClickOnMember(this.state.show);
      }
    });
  };

  handleSubHover = () => {
    const { onHover = () => {} } = this.props;
    onHover();
    this.hover = true;
  }

  handleSubBlur = () => {
    const { onBlur = () => {} } = this.props;
    onBlur();
    this.hover = false;
  }

  getLineNewStyle = (index) => {
    const { isNewStyle } = this.props;
    if (!isNewStyle) return false;
    if (index === 0 && this.state.options.length === 0) return false;
    return true;
  };

  actionRender = (item, index) => {
    const { text } = this.state;
    const { isNewStyle, type } = this.props;
    return <React.Fragment key={index}>
      {this.getLineNewStyle(index) && !item.tip && <div className='border-line' />}
      <div
        className={classNames(
          'dropdown-checkbox__item dropdown-checkbox__itemaction',
          {
            'dropdown-checkbox__chkitem': item.label === text,
            'dropdown-checkbox__smitem': type === 'sm',
            'dropdown-checkbox__point': item.isRed,
            'dropdown-checkbox__hover_old': !isNewStyle,
            'dropdowm-checkbox__hover_recovery': item.tip,
          },
        )}
        onClick={this.handleSelectManage.bind(this, item, index)}
    >
        {
          item.tip
            ? <Tooltip
                placement='top'
                overlayStyle={{ minWidth: 220, zIndex: 9999 }}
                title={intl.t('恢复后，将与团队空间/父文件夹权限保持一致')}
            >
              <p className='recovery_label name'>{item.label}<span className='recovery_tip name'>{item.tip}</span></p>
            </Tooltip>
            : <span className='name'>{item.label}</span>
        }
        <i className='dropdown-checkbox__ico' />
      </div>
    </React.Fragment>;
  }

  render() {
    const { text, show } = this.state;
    const { actions = [], wrapClassNames, type, showDesc, changeDisabled, isNewStyle, overlayClassName, removeStatus, placement = 'right' } = this.props;
    const curLocale = getLocale() === 'zh-CN';
    return (
      <div
        className={classNames('dropdown-checkbox', {
          'dropdown-smcheckbox': type === 'sm',
          active: show,
          'dropdown-checkbox-disabled': changeDisabled,
          'dropdown-checkbox-abled': !changeDisabled,
        }, wrapClassNames)}
        onClick={this.handleClick}
      >
        <TetherComponent
          attachment={`top ${placement}`}
          targetAttachment={`bottom ${placement}`}
          constraints={[
            {
              to: 'scrollParent',
              attachment: 'together',
            },
          ]}
        >
          <span
            className='dropdown-checkbox__value'
            ref={(p) => {
              this.el = p;
            }}
          >
            <i className='dropdown-checkbox__caret' />
            {text === intl.t('请选择') ? (
              <span
                style={{
                  color: '#bec5d2',
                }}
              >
                {text}
              </span>
            ) : (
              <span>{text}</span>
            )}
          </span>
          {!changeDisabled && show && (
            <div
              className={classNames('dropdown-checkbox__wrapper', {
                // 新版本样式
                'dropdown-checkbox_cooper': isNewStyle,
              }, overlayClassName)}
              id='dropdown-checkbox__wrapper_mask'
              onMouseEnter={this.handleSubHover}
              onMouseLeave={this.handleSubBlur}
            >
              <div className='dropdown-checkbox__items'>
                {actions.filter((v) => v.placement === 'top').map(this.actionRender)}
                {this.state.options.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={classNames('dropdown-checkbox__item', {
                        'dropdown-checkbox__chkitem': item.checked,
                        'dropdown-checkbox__smitem': type === 'sm',
                        'dropdown-checkbox__disableitem_global': item.disabled,
                        'dropdown-checkbox__hover_old': !isNewStyle,
                      })}

                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        this.handleSelect(item);
                      }}
                      onTouchStart={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <span className='name'>{item.label}</span>
                      {showDesc ? (
                        <span className='desc'>{item.desc}</span>
                      ) : null}
                      <i className='dropdown-checkbox__ico' />
                    </div>
                  );
                })}
                {actions.filter((v) => v.placement !== 'top').map(this.actionRender)}
              </div>
            </div>
          )}
        </TetherComponent>
      </div>
    );
  }
}

const WrappedDropdownCheckbox = onClickOutside(DropdownCheckbox);
export default WrappedDropdownCheckbox;
