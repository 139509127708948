import * as PageService from '@/service/knowledge/page';
import { TAG_SOURCE_TYPE } from '@/constants/index';
import * as PageTreeService from '@/service/knowledge/pageTree';
import * as DidocService from '@/service/knowledge/didoc';
import { editorScreenshot, getEditorSummary } from '@/utils';
import { DocType } from '@/constants/page';
import { getSheetVersion } from '@/utils/shimo-excel';


const getDefaultState = () => ({
  docInfo: {},
  initLoading: true,
  isFirstInit: true,
  view: {},
  searchMembers: [],
  individualMembers: [],
  inheritMembers: [],
  tags: [],
  saveStatus: '',
  collaborators: [],
  saveAsTemplateModalVisible: false,
  writerVisible: false,
  editReady: false,
  changeBlock() { },
  isBlock: false,
  editorKey: Date.now(), // 编辑器组件key，用于刷新编辑器
  previewFileId: {
    inEdit: '',
    inDetail: '',
  },
  previewFileInfo: {},
  alertTip: null,
});
export default {
  state: getDefaultState(),
  reducers: {
    resetState(state) {
      return {
        ...getDefaultState(),
        isFirstInit: false,
      };
    },
    changeBlock(state, isBlock) {
      return {
        ...state,
        isBlock,
      };
    },
    changeStar(state, isStar) {
      return {
        ...state,
        docInfo: {
          ...state.docInfo,
          markedStar: isStar,
        },
      }
    },
    changeInitLoading(state, initLoading) {
      return {
        ...state,
        initLoading,
      };
    },
    changePreviewFileId(state, previewFileId) {
      return {
        ...state,
        previewFileId,
      };
    },
    changePreviewFileIdInEdit(state, id) {
      return {
        ...state,
        previewFileId: {
          ...state.previewFileId,
          inEdit: id,
        },
      };
    },
    changePreviewFileInfo(state, previewFileInfo) {
      return {
        ...state,
        previewFileInfo,
      };
    },
    changePreviewFileName(state, name) {
      return {
        ...state,
        previewFileInfo: {
          ...state.previewFileInfo,
          previewFileName: name,
        },
      };
    },
    setPerm(state, permission) {
      return {
        ...state,
        permission,
      };
    },
    changeEditReady(state, editReady) {
      return {
        ...state,
        editReady,
      };
    },
    refreshEditorKey(state) {
      return {
        ...state,
        editorKey: Date.now(),
      };
    },
    changeDocInfo(state, docInfo) {
      return {
        ...state,
        docInfo: docInfo,
        editorKey: Date.now(), // 每次查询详情都刷新编辑器
      };
    },
    changeDocInfoInName(state, docInfo) {
      return {
        ...state,
        docInfo,
      };
    },
    changeIsFirstInit(state, isFirstInit) {
      return {
        ...state,
        isFirstInit,
      };
    },
    setPageStyle(state, pageStyle) {
      return {
        ...state,
        docInfo: {
          ...state.docInfo,
          pageStyle: {
            ...state.docInfo.pageStyle,
            ...pageStyle,
          },
        },
      }
    },
    changeView(state, view) {
      return {
        ...state,
        view,
      };
    },
    changeSaveStatus(state, saveStatus) {
      return {
        ...state,
        saveStatus,
      };
    },
    changeCollaborators(state, collaborators) {
      return {
        ...state,
        collaborators: [...collaborators],
      };
    },
    changeSearchMembers(state, refresh, searchMembers) {
      const newMembers = refresh ? [...searchMembers] : [...state.searchMembers, ...searchMembers];
      return {
        ...state,
        searchMembers: newMembers,
      };
    },
    changeIndividualMembers(state, refresh, individualMembers) {
      const newMembers = refresh ? [...individualMembers] : [...state.individualMembers, ...individualMembers];
      return {
        ...state,
        individualMembers: newMembers,
      };
    },
    changeInheritMembers(state, refresh, inheritMembers) {
      const newMembers = refresh ? [...inheritMembers] : [...state.inheritMembers, ...inheritMembers];

      return {
        ...state,
        inheritMembers: newMembers,
      };
    },
    changeTags(state, tags) {
      return {
        ...state,
        tags: [...tags],
      };
    },
    toggleWriterVisible(state) {
      return {
        ...state,
        writerVisible: !state.writerVisible,
      };
    },
    setWriterVisible(state, writerVisible) {
      return {
        ...state,
        writerVisible,
      };
    },
    toggleSaveAsTemplateModalVisible(state) {
      return {
        ...state,
        saveAsTemplateModalVisible: !state.saveAsTemplateModalVisible,
      };
    },

    addPageTagsChange(state, newTag) {
      const { tags } = state;
      // newTagList.map((item) => {
      //   tags.unshift(item);
      //   return item;
      // });
      let index = 0;
      if (tags[0]?.sourceType === TAG_SOURCE_TYPE.SYSTEM_SECURE) {
        index = 1;
      }
      tags.splice(index, 0, {
        id: newTag.tagId,
        name: newTag.tagName,
        sourceType: TAG_SOURCE_TYPE.CUSTOM,
        ownPerm: true,
      });

      return state;
    },
    delPageTagsChange(state, delTagId) {
      let { tags } = state;

      // state.tags = tags.filter((item) => {
      //   return delTagIds.indexOf(item.id) < 0;
      // });
      state.tags = tags.filter((item) => item.id !== delTagId);
      return state;
    },
    changeTitle(state, { name, pageId }) {
      const { docInfo } = state;
      const { multiPath = [] } = docInfo;
      const cloneMultiPath = multiPath.map((item) => {
        const path = {
          ...item,
        };
        if (item.id === pageId) {
          path.name = name;
        }
        return path;
      });
      const pageName = pageId === docInfo.pageId ? name : docInfo.pageName;
      return {
        ...state,
        docInfo: {
          ...docInfo,
          pageName,
          multiPath: cloneMultiPath,
        },
      };
    },
    setAlertTip(state, alertTip) {
      return {
        ...state,
        alertTip,
      }
    },
  },
  effects: {
    async getPageDetail(payload) {
      this.resetState();
      let startTime = new Date().getTime();
      const data = await PageService.getPageDetail(payload);
      let endTime = new Date().getTime();
      window.__OmegaEvent('tech_ep_network_load_time', '知识库核心接口加载时长', {
        source: 'details',
        time: endTime - startTime,
      })
      if (data) {
        this.changeDocInfo(data);
        this.changeIsFirstInit(false);
        this.changePreviewFileId({
          inEdit: data.guid,
          inDetail: data.latestVersion,
        });
      }

      return data;
    },
    async resetPageDetail(payload) {
      let startTime = new Date().getTime();
      const data = await PageService.getPageDetail(payload);
      let endTime = new Date().getTime();
      window.__OmegaEvent('tech_ep_network_load_time', '知识库核心接口加载时长', {
        source: 'details',
        time: endTime - startTime,
      })
      if (data) {
        this.changeDocInfo(data);
        this.changeIsFirstInit(false);
        this.changePreviewFileId({
          inEdit: data.guid,
          inDetail: data.latestVersion,
        });
      }

      return data;
    },
    async setPageDetail(payload) {
      if (payload) {
        await this.changeDocInfo(payload);
        this.changeIsFirstInit(false);
        this.changePreviewFileId({
          inEdit: payload.guid,
          inDetail: payload.latestVersion,
        });
      }
      return payload;
    },
    async searchPageMembers({ refresh, params }) {
      const data = await PageService.searchPageMembers(params);
      if (data && data.items) {
        this.changeSearchMembers(refresh, data.items);
      }
      return data;
    },
    async getPageInheritMembers({ refresh, params }) {
      const data = await PageService.getPageInheritMembers(params);
      if (data && data.items) {
        this.changeInheritMembers(refresh, data.items);
        // this.getPagePageInheritMembersAloneperm({ items: data.items, params, refresh });
      }
      return data;
    },
    // 暂时废弃
    async getPagePageInheritMembersAloneperm({ items, params, refresh }) {
      const resultArray = await Promise.all(items.map(async (item) => {
        if (item.sources === 'ALONE_PERM') {
          const { principalType = null, principalMemberId = null } = item;
          const newParams = {
            pageId: params.pageId,
            principalType,
            principalMemberId,
            knowledgeMember: true,
          };
          const res = await PageService.getPageIndividualMembersAloneperm(newParams);
          const newItem = { ...item, operationListNew: res };
          return newItem;
        } else {
          return item;
        }
      }));
      this.changeInheritMembers(refresh, resultArray);
      return resultArray;
    },

    async getPageIndividualMembers({ refresh, params }) {
      const data = await PageService.getPageIndividualMembers(params);
      if (data && data.items) {
        this.changeIndividualMembers(refresh, data.items);
        // this.changeIndividualMembers(refresh, data.items);
        // this.getPageIndividualMembersAloneperm({ items: data.items, params, refresh });
      }
      return data;
    },
    // 暂时废弃
    async getPageIndividualMembersAloneperm({ items, params, refresh }) {
      const resultArray = await Promise.all(items.map(async (item) => {
        const { principalType = null, principalMemberId = null } = item;
        const newParams = {
          pageId: params.pageId,
          principalType,
          principalMemberId,
          knowledgeMember: false,
        };
        const res = await PageService.getPageIndividualMembersAloneperm(newParams);
        const newItem = { ...item, operationListNew: res };
        return newItem;
      }));
      this.changeIndividualMembers(refresh, resultArray);
      return resultArray;
    },

    async getAlonePerm(payload) {
      const res = await PageService.getPageIndividualMembersAloneperm(payload);
      return res;
    },

    async pageRename(payload) {
      await PageTreeService.renameCurrPage(payload);
      this.changeTitle(payload);
    },
    async uploadCover(payload) {
      const blob = await editorScreenshot();
      const formData = new FormData();
      formData.append('file', blob, payload);
      const { url } = await DidocService.uploadCover(formData);
      return url;
    },
    async releasePage(payload, { pageDetail }) {
      const { docInfo, view, previewFileId } = pageDetail;
      const { pageId, pageName, guid, externalSystemType } = docInfo;
      const { isPreview, isSheet, previewFileName } = payload;
      if (isPreview) {
        return PageService.releasePage({
          pageId,
          title: previewFileName,
          version: previewFileId.inEdit,
          pageType: DocType.DK_FILE,
          externalSystemType,
          guid,
        });
      }
      if (isSheet) {
        await view.getEditor().createRevision();
        const version = await getSheetVersion(guid);
        return PageService.releasePage({
          pageId,
          title: pageName,
          version,
          thumbnail: '',
          summary: '',
        });
      }
      const { title } = view.template.export();
      const version = view.getVersion();
      const summary = getEditorSummary(view);
      return PageService.releasePage({
        pageId,
        title,
        version,
        thumbnail: '',
        summary,
        externalSystemType,
        guid,
      });
    },
    async reissueApproval(payload, { pageDetail }) {
      const { docInfo, previewFileId } = pageDetail;
      const { pageId } = docInfo;
      const { isPreview, previewFileName, view } = payload;
      if (isPreview) {
        return PageService.reissueApproval({
          pageId,
          title: previewFileName,
          version: previewFileId.inEdit,
          pageType: DocType.DK_FILE,
        });
      }
      const { title } = view.template.export();
      const version = view.getVersion();
      const summary = getEditorSummary(view);
      return PageService.reissueApproval({
        pageId,
        title,
        version,
        thumbnail: '',
        summary,
      });
    },
    async getPageTags(payload) {
      const data = await PageService.getPageTags(payload);
      if (data) {
        this.changeTags(data);
      }
    },
    async addPageTags(payload) {
      const tagId = await PageService.addPageTags(payload);

      if (tagId) {
        this.addPageTagsChange({ ...payload, tagId });
      }

      return tagId;
    },
    async delPageTags(payload) {
      const data = await PageService.delPageTags(payload);
      if (data) {
        this.delPageTagsChange(payload.tagId);
      }
    },
    async getMentionList(_, { pageDetail }) {
      const { view } = pageDetail;
      const mentionList = view.getMentionList ? view.getMentionList() : [];

      return mentionList;
    },
    async updatePageStyle(payload) {
      await PageService.updatePageStyle(payload);
      this.setPageStyle({ ...payload })
    },
  },
};
