
import classBind from 'classnames/bind';
import styles from './style.module.less';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash-es';
import { Checkbox, Divider, Tooltip } from 'antd';
import { intl } from 'di18n-react';

const cx = classBind.bind(styles);

// 初始化数据最近访问
const initRecentFilter = ['coo_doc', 'coo_sheet', 'coo_ppt', 'coo_file', 'flow', 'mind'];
const initTenantRecentFilter = ['all'];
// 初始化数据最近编辑
const initEditeFilter = ['coo_doc', 'coo_sheet', 'coo_ppt', 'flow', 'mind'];
const initTenantEditeFilter = ['all'];

const recentFilterAll = ['coo_doc', 'coo_sheet', 'coo_ppt', 'coo_file', 'wiki', 'flow', 'mind'];
const recentFilterAllTenant = ['coo_doc', 'coo_sheet', 'coo_file'];

const editeFilterAll = ['coo_doc', 'coo_sheet', 'coo_ppt', 'wiki', 'flow', 'mind'];
const editeFilterAllTenant = ['coo_doc', 'coo_sheet'];

const searchType = (props) => {
  // eslint-disable-next-line max-len
  const { rowKey, isRecentTab, type, valueMap, filterMsg, updateFilteredMsg, doFilters } = props;
  let checkedValue = filterMsg.type.split(',') || [];
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { IsExternalTenant } = useSelector((state) => state.GlobalData);
  // 工具函数，生成所需的常量
  const _getInitRecentFilter = () => {
    if (IsExternalTenant) {
      return initTenantRecentFilter
    }
    return initRecentFilter
  }
  const _getInitEditeFilter = () => {
    if (IsExternalTenant) {
      return initTenantEditeFilter
    }
    return initEditeFilter
  }
  const _getRecentFilterAll = () => {
    if (IsExternalTenant) {
      return recentFilterAllTenant
    }
    return recentFilterAll
  }
  const _getEditeFilterAll = () => {
    if (IsExternalTenant) {
      return editeFilterAllTenant
    }
    return editeFilterAll
  }
  const filterToolitip = () => {
    return (
      <div className={cx('filter-tip-hover-content')}>
        <p className={cx('filter-tip-hover-content-title')}>
          {intl.t('类型说明')}
        </p>
        {IsExternalTenant ? (
          <>
            <p>{intl.t('文档：Cooper协作文档')}</p>
            <p>{intl.t('表格：Cooper协作表格')}</p>
            <p>{intl.t('文件：本地上传的Doc、Excel、PDF等文件')}</p>
          </>
        ) : <>
          <p>{intl.t('文档：Cooper协作文档、匿名文档、知识库文档')}</p>
          <p>{intl.t('表格：Cooper协作表格')}</p>
          <p>{intl.t('流程图：Cooper协作流程图')}</p>
          <p>{intl.t('思维导图：Cooper协作思维导图')}</p>
          <p>{intl.t('幻灯片：Cooper协作幻灯片')}</p>
          <p>{intl.t('文件：Cooper、知识库上传的本地文件')}</p>
          <p>{intl.t('Wiki页面：Wiki页面')}</p>
        </>}
      </div>
    );
  };

  const checkArrIsSame = (initFileArr) => {
    let filterArr = initFileArr.filter((item) => checkedValue.includes(item));
    return filterArr.length === initFileArr.length;
  };

  const handleFilters = (typeData) => {
    let obj = updateFilteredMsg(typeData, checkedValue.join(','));
    doFilters(obj, typeData);
  };

  const handleClickFilter = debounce(
    (value, typeData) => {
      if (!checkedValue.includes(value)) {
        if (value === 'all') {
          checkedValue = ['all'];
        } else {
          checkedValue.push(value);
          if (checkedValue.includes('all')) {
            checkedValue.splice(checkedValue.indexOf('all'), 1);
          }
          if (checkArrIsSame(isRecentTab ? _getRecentFilterAll() : _getEditeFilterAll())) {
            checkedValue = ['all'];
          }
        }
        handleFilters(typeData);
      } else if (checkedValue.length === 1) {
        return false;
      } else {
        checkedValue.splice(checkedValue.indexOf(value), 1);
        handleFilters(typeData);
      }
    },
    500,
    {
      leading: true,
      trailing: false,
    },
  );


  const resentFilter = debounce(
    (typeData) => {
      let currValue = isRecentTab ? _getInitRecentFilter() : _getInitEditeFilter();
      if (checkArrIsSame(currValue)) return;
      checkedValue = currValue;
      handleFilters(typeData);
    },
    500,
    {
      leading: true,
      trailing: false,
    },
  );
  return (
    <ul
      className={cx('custom-filter-dropdown')}
      key={rowKey}>
      <li
        className={cx({
          'filter-resent': true,
          'filter-action': !checkArrIsSame(
            isRecentTab ? _getInitRecentFilter() : _getInitEditeFilter(),
          ),
        })}
        onClick={() => resentFilter(type)}
      >
        {intl.t('重置')}{' '}
      </li>
      <Divider className={cx('filter-divider')} />
      <li className={cx('filter-tip')}>
        {intl.t('类型可多选')}
        <Tooltip
          title={filterToolitip()}
          overlayClassName="filter-tooltip">
          <i className={cx('dk-iconfont', 'dk-icon-a-tishi2', 'icon-tip')} />
        </Tooltip>
      </li>
      {valueMap.map((item) => {
        return (
          <li key={item.value}>
            <Checkbox
              checked={checkedValue.includes(item.value)}
              onChange={() => handleClickFilter(item.value, type)}
              value={item.value}>
              {item.text()}
            </Checkbox>
          </li>
        );
      })}
    </ul>
  );
}

export default searchType
