import classBind from 'classnames/bind';
import styles from './style.module.less';
import DynamicPopover from '@/components/DynamicPopover';
import Feedback from '../../Feedback';
import { useEffect, useState } from 'react';
import { setUserView } from '@/service/cooper/userView';
import { intl } from 'di18n-react';
import { Button } from 'antd';
// import { WIDTH } from '@/components/common/CooperTable/constant';
// import { isDiDiTenant } from '@/utils/entryEnhance';

const cx = classBind.bind(styles);

function FeedbackWithPopup({ renderKey, placement, showGuide = false }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(showGuide);
  }, [showGuide]);

  const iknowHandler = () => {
    (async function () {
      await setUserView({ feedbackGuide: false }, 'ai_customer_service_guide');
      setShow(false);
    }());
  };

  const getContent = () => <div className={cx('popup-main')} key='popup-main'>
    <div className={cx('popup-main-title')}>{intl.t('智能客服全新上线，答疑更便捷！')}</div>
    <div><Button className={cx('popup-main-iknow')} onClick={iknowHandler} type="primary">{intl.t('我知道了')}</Button></div>
  </div>;

  return (
    <div className={cx('feedback-with-popup')}>
      <DynamicPopover
        showOrNot={show}
        content={getContent()}
        placement={placement}
        key={renderKey}
        closeCallback={iknowHandler}>
        <Feedback />
      </DynamicPopover>
    </div>
  );
}

export default FeedbackWithPopup;
