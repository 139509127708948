/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-05-19 15:41:26
 * @LastEditTime: 2024-04-18 10:17:02
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/utils/request/api/CooperApi.js
 *
 */

import { get } from 'lodash';

const mockMain = (mockType) => {
  return `https://mock.xiaojukeji.com/mock/${mockType}`;
};
const getApi = ({
  path,
  service = '/adapter',
  version = '/v1',
  mock,
  originService = '/cooper_gateway',
  mockType = 4841,
}) => {
  if (mock) {
    return `${mockMain(mockType) + service + version + path}`;
  }
  return `${`${originService}${service}${version}${path}`}`;
};
export default {
  // 首页快速访问
  QUICK_VISIT: getApi({
    path: '/quick/visit',
    service: '/kaleido',
    version: '/v2',
  }),
  GET_QUICK_STATUS: getApi({
    path: '/quick/visit/withCondition',
    service: '/kaleido',
    version: '/v2',
  }),
  // 首页里面最近访问、最近编辑列表
  RECENT_FILE_LIST: getApi({
    path: '/resources/recent/:queryType',
    service: '/kaleido',
  }),
  GET_SUITLIST: '/gateway/teamcenter/v1/querySuiteSourceUrl',
  // RESOURCE_DOC: '/resource/:resourceId/doc',
  // 获取写作文档摘要
  RESOURCE_DOC: getApi({ path: '/resource/:resourceId/doc' }),
  // 获取完整路径
  QUERY_FILE_DIR: getApi({ path: '/dir/path', service: '/kaleido' }),
  SET_VISITED_SUITE:
    '/cooper_gateway/kaleido/v1/space/suite/visited/:teamId/:suitType',
  // 预览
  // 转换成协作文档
  PREVIEW_IMPORT: getApi({ path: '/resource/:id/preview/import' }),
  // GET [5.5]获取直接分享文件预览的链接
  DIRECT_RESOURCE_PREVIEW: getApi({
    path: '/direct/:shareId/resource/:resourceId/preview',
    service: '/preview',
  }),
  DIRECT_VIDEO_PREVIEW: getApi({
    path: '/direct/:shareId/video/:resourceId/preview',
    service: '/preview',
  }),
  LINK_RESOURCE_PREVIEW: getApi({
    path: '/link/:shareId/resource/:resourceId/preview',
    service: '/preview',
  }),
  LINK_VIDEO_PREVIEW: getApi({
    path: '/link/:shareId/video/:resourceId/preview',
    service: '/preview',
  }),
  RESOURCE_META: getApi({
    path: '/resource/:resourceId/meta',
    service: '/preview',
  }),
  RESOURCE_PREVIEW: getApi({
    path: '/resource/:resourceId/preview',
    service: '/preview',
  }),
  VIDEO_PREVIEW: getApi({
    path: '/video/:resourceId/preview',
    service: '/preview',
  }),
  // 批量删除文件
  RESOURCES_BATCH_DELETE: getApi({
    path: '/resources/batch-delete',
    version: '/v2',
  }),

  SHARE_SHAREID: '/cooper_gateway/adapter/v1/shares/:shareId',
  SHARES_FROM_ME_LIST: '/cooper_gateway/kaleido/v1/shares',
  SHARE_DETAILE: '/cooper_gateway/kaleido/v1/share/detail/:resourceId',
  SHARES_SHARE: '/cooper_gateway/share/v1/shares',
  ADAPTER_SHARE_LINK: '/cooper_gateway/adapter/v1/shares/link',
  DIRECT_PERMISSIONS: '/cooper_gateway/adapter/v1/direct/permissions',

  API_DOWNLOADS: getApi({ path: '/download/space/:teamId' }),
  API_DIRECT_DOWNLOAD: getApi({ path: '/download/share/direct/:directId' }),
  API_LINK_DOWNLOAD: getApi({ path: '/download/share/link/:linkId' }),

  // 文件重命名
  RESOURCES_RENAME: getApi({
    path: '/resources/:resourceId/rename',
  }),

  // 团队重命名
  ADAPTER_TEAMS: getApi({
    path: '/teams',
  }),

  // 团队空间容量
  TEAM_SPACE_QUOTA_DETAIL: getApi({ path: '/team/:teamId/usedQuotaDetail' }),

  // 删除团队
  ADAPTER_TEAMS_TEAMID: getApi({ path: '/teams/:teamId' }),

  // 退出团队
  GEMINI_MEMBERS: getApi({ path: '/space/:teamId/members', service: '/gemini' }),
  // GET [4.43] 校验申请成为团队空间所有者
  OWNER_CHECK: getApi({
    path: '/space/:id/apply/owner/check',
    service: '/gemini',
  }),
  // POST [4.44] 发起团队空间所有者申请
  APPLY_OWNER: getApi({
    path: '/space/:id/apply/owner',
    service: '/gemini',
  }),

  // 删除回收站中团队空间接口
  SPACE_REMOVE: getApi({ path: '/trash/space/remove' }),

  // POST [4.22] 创建协作文档链接
  // PUT [4.23] 更新协作链接
  COOPERATE_LINK: getApi({
    path: '/cooperate/link',
  }),

  // 查询历史
  MOVE_RECORD: getApi({
    path: '/directory/space/search/record',
  }),
  // 点击搜索结果后的展开
  MOVE_UNFOLD: getApi({
    path: '/directory/space/unfold',
  }),
  // 我参与的团队
  TEAM_LIST: getApi({
    path: '/teams',
    service: '/kaleido',
  }),
  // 我拥有的团队
  TEAM_LIST_OWNER: getApi({
    path: '/tab/teams',
    service: '/kaleido',
  }),
  // 团队空间获取下层文件夹（v2，带目录权限）
  TEAM_RESOURCE_FOLD_V2: getApi({
    path: '/team/:teamId/directory/resource/:resourceId/folds',
    version: '/v2',
  }),
  // 个人空间获取下层文件夹
  PERSONAL_RESOURCE_FOLD: getApi({
    path: '/personal/directory/resource/:resourceId/folds',
  }),
  // 移动资源
  DIRECTORY_MOVE: getApi({
    path: '/directory/move',
  }),
  // 复制资源
  COPY_FILE: getApi({
    path: '/copy/file',
  }),
  // 获取复制进度
  COPY_PROGRESS: getApi({
    path: '/copy/progress',
  }),
  // 创建文件夹
  CREATE_DIRECTORY: getApi({
    path: '/directory',
  }),
  // 分享资源另存为
  SHARE_COPY: getApi({
    path: '/shares/copy',
  }),
  // 团队空间获取下层文件夹
  TEAM_RESOURCE_FOLD: getApi({
    path: '/team/:teamId/directory/resource/:resourceId/folds',
  }),
  // 团队置顶
  TEAMS_PIN: getApi({ path: '/teams/pin', service: '/kaleido' }),

  // 获取所连接的站点所在地
  CONNECT_SITE_ID: getApi({
    path: '/app/region',
    service: '',
  }),
  // 消息通知 列表
  API_NOTIFICATIONS_UNREAD: getApi({
    path: '/notifications/unread',
    service: '/iris',
  }),
  API_NOTIFICATIONS_READ: getApi({
    path: '/notifications/read',
    service: '/iris',
  }),

  // 消息通知 全部标记已读
  API_NOTIFICATION_SIGN_ALL_READ: getApi({
    path: '/notifications/before/:latestTs/read',
    service: '/iris',
  }),

  // 消息列表 标记已读
  API_NOTIFICATION_SIGN_READ: getApi({
    path: '/notifications/:notificationId/read',
    service: '/iris',
  }),

  // 消息列表 空间/文档所有权审批或拒绝
  APPROVE_COOPERATION_APPLY: getApi({
    path: '/owner/apply/complete',
    service: '/gemini',
    version: '/v1',
  }),
  APPROVE_COOPERATION_SPACE_APPLY: getApi({
    path: '/spaceOwner/apply/complete',
    service: '/gemini',
    version: '/v1',
  }),

  APPROVE_COOPERATION_DOC_APPLY: getApi({
    path: '/transfer/docOwner/apply/complete',
    service: '/cooperation',
    version: '/v1',
  }),

  APPROVE_HIGH_PERM_DOC_APPLY: getApi({
    path: '/resource/apply/approval',
    service: '/cooperation',
    version: '/v1',
  }),

  // 同意、拒绝 协作申请
  COOPERATION_APPLY: getApi({
    path: '/cooperation/apply',
    version: '/v2',
  }),

  // 审批加入团队
  TEAM_APPLY_APPROVE: getApi({
    path: '/team/apply/:applyId/approve',
    service: '/gemini',
    version: '/v2',
  }),

  // 创建普通文件分享
  CREATE_SHARE: getApi({
    path: '/share',
  }),

  // 定向分享搜索用户
  SEARCH_DIRECT_SHARE: getApi({
    path: '/search/directShare',
    version: '/v2',
  }),

  // 定向分享通知接口
  DIRECT_SHARE: getApi({
    path: '/direct/share',
  }),

  // 批量定向分享
  BATCH_DIRECT_SHARE: getApi({
    path: '/direct/share/batch',
  }),
  // 移动、复制中的搜索
  MOVE_SEARCH: getApi({
    path: '/directory/space/search',
  }),
  // 移动、复制中的搜索（返回树）
  MOVE_SEARCH_TREE: getApi({
    path: '/directory/space/search/tree',
  }),
  // 文件路径面包屑
  DIRECTORY_CRUMBS: getApi({
    path: '/directory/:id/crumbs',
  }),
  // 文件路径面包屑-分享
  DIRECTORY_CRUMBS_SHARE: getApi({
    path: '/share/:shareId/directory/:id/crumbs',
  }),
  // 创建协作文档
  SPACE_DOC: getApi({
    path: '/space/:teamId/doc',
    version: '/v2',
  }),
  // 导入协作文档
  SPACE_DOC_IMPORT: getApi({
    path: '/space/:teamId/doc/import',
  }),
  // 图标相关
  CREATE_DRAW: getApi({
    path: '/createDrawFile',
  }),
  // 修改空间排序
  SPACE_PROFILE: getApi({
    path: '/space/configs',
  }),
  // QUERY_TEMPLATE_TYPE, QUERY_TEMPLATE_LIST, QUERY_ALL_TYPE_LIST, SPACE_DOC
  QUERY_TEMPLATE_TYPE: getApi({
    path: '/template/categories/:fileType',
    service: '/kaleido',
  }),
  QUERY_TEMPLATE_LIST: getApi({
    path: '/template/items/:fileType',
    service: '/kaleido',
  }),
  QUERY_ALL_TYPE_LIST: getApi({
    path: '/template/allItems/:fileType',
    service: '/kaleido',
  }),
  PIN_SPACE: getApi({ path: '/pin/space/:teamId', service: '/kaleido' }),
  PO_TOKEN: '/cooper_gateway/cooperation/v1/po/template/token',

  // 文件上传
  ADD_RAY: getApi({ path: '/disk/addRay' }),
  PERMISSIONS: getApi({ path: '/space/upload/check' }),
  CREATE_TASK: getApi({ path: '/uploadTask/space/:teamId' }),
  CREATE_TASK_P: getApi({ path: '/personal/uploadTask/space/:teamId' }),
  ADD_TASK: getApi({
    path: '/uploadTask/:taskId/space/:teamId/parent/:parentId',
  }),
  ADD_TASK_P: getApi({
    path: '/personal/uploadTask/:taskId/space/:teamId/parent/:parentId',
  }),
  TASK_TOKEN: getApi({
    path: '/uploadTask/:taskId/space/:teamId/parent/:parentId/token',
  }),
  TASK_TOKEN_P: getApi({
    path: '/personal/uploadTask/:taskId/space/:teamId/parent/:parentId/token',
  }),
  FILE_IDS: getApi({
    path: '/uploadTask/:taskId/space/:teamId/parent/:parentId/detail',
  }),
  FILE_IDS_P: getApi({
    path: '/personal/uploadTask/:taskId/space/:teamId/parent/:parentId/detail',
  }),
  DUPLICATE_NAME: getApi({
    path: '/directory/space/:teamId/parent/:parentId/resources',
  }),
  CHECK_FILE: '/platform/stream/v1/check-files/:token/:fileId',
  CHECK_SLICE: '/platform/stream/v1/files/:token/:fileId/:seqId/check',
  SLICE_UPLOAD: '/platform/stream/v1/files/:token/:fileId/:seqId',
  FILE_COMPLETE: '/platform/stream/v1/fileMd5/:token/:fileId',
  MD5_BUFFER_WORKER: '/js/md5-buffer.js',

  GET_RECENT_DATA_SEARCH: getApi({
    path: '/search/recent/all',
    service: '/kaleido',
  }),

  // 团队空间
  // 最近访问团队
  TEAMS_RECENT: getApi({ path: '/teams/recent', service: '/kaleido' }),
  // 我创建的团队 or 我参与的团队
  SPACE_QUOTA_APPLY_QUERY: getApi({ path: '/space/quota/apply/query' }),

  // 获取内外互联空间的列表
  SPACE_LIST_OUT_LIST: getApi({ path: '/teams/in_out/created', version: '/v2', service: '/adapter' }),
  // 删除内外互联空间列表
  SPACE_TEAM_REMOVE_IN_OUT: getApi({ path: '/teams/in_out/remove/:applyId', version: '/v2', service: '/adapter' }),
  // 申请扩容
  SPACE_QUOTA_APPLY: getApi({ path: '/space/:teamId/quota/apply' }),

  // 个人空间配额
  PERSONAL_SPACE_QUOTA_DETAIL: getApi({ path: '/personal/space/quota/detail' }),

  // 我的收藏 or 收藏文件
  STAR_LIST: getApi({ path: '/star', service: '/kaleido', version: '/v2' }),

  // 个人空间目录列表
  PERSONAL_SPACE_FILES: getApi({
    path: '/personal/space/files',
    version: '/v2',
    service: '/adapter',
  }),

  // 团队空间目录列表
  TEAM_SPACE_FILES: getApi({
    path: '/team/:teamId/space/files/page',
    version: '/v2',
  }),
  RESOURCE_CONTACT: getApi({ path: '/resource/:resourceId/contact' }),
  // 团队详情
  TEAM_DETAIL: getApi({ path: '/team/:teamId/detail' }),

  // 获取空间功能列表
  TEAM_FUNCTION: getApi({ path: '/space/function/list/:teamId' }),

  // 更新空间功能列表
  UPDATE_TEAM_FUNCTION: getApi({ path: '/space/function/replaceAll' }),

  // 获取空间功能列表
  TEAM_PERSON_FUNCTION: getApi({ path: '/space/personal/function/list' }),

  // 团队动态
  TEAM_DYNAMIC: getApi({
    path: '/space/dynamic',
    service: '/kaleido',
    version: '/v2',
  }),

  // 保存团队布局
  TEAM_LAYTOUT: getApi({ path: '/space/layout/:teamId' }),

  // 获取团队空间知识库列表
  TEAM_KNOWLEDGE_LIST: getApi({ path: '/space/:teamId/knowledge/list' }),

  // 获取团队空间知识库列表
  TEAM_KNOWLEDGE_DYNAMIC: getApi({ path: '/space/knowledge/dynamic' }),

  // 获取团队空间回收站列表
  TEAM_TRASH_LIST: getApi({ path: '/trash/list' }),

  // 获取团队空间回收站列表
  TEAM_SPACE_TRASH_LIST: getApi({ path: '/trash/space/list', service: '/kaleido' }),

  // 是否有重名的空间
  SPACE_NAME_CHECK: getApi({ path: '/trash/space/name/check' }),

  // 回收站-恢复前判断父目录是否存在
  TEAM_TRASH_PARENT_EXIST: getApi({
    path: '/trash/space/:teamId/resource/:resourceId/parent/exist',
  }),

  // 回收站-恢复
  TEAM_TRASH_RESTORE: getApi({
    path: '/trash/space/:teamId/resource/:resourceId/restore',
  }),

  // 恢复团队空间
  SPACE_RESTORE: '/cooper_gateway/adapter/v1/trash/space/restore',

  // 还原团队空间内容
  REDUCTION_PROGRESS: '/cooper_gateway/adapter/v1/trash/space/restore/progress?taskId=:taskId',

  // 回收站-彻底删除
  TEAM_TRASH_REMOVE: getApi({
    path: '/trash/space/:teamId/resource/:resourceId/remove',
  }),
  // 团队名校验
  TEAM_EXIST: getApi({ path: '/team/isExist' }),

  // 创建团队
  CREATE_TEAMS: getApi({ path: '/teams/create', version: '/v2' }),

  // 申请加入团队
  APPLYU_TEAM_JOIN: getApi({
    path: '/team/:teamName/join',
    // path: '/team/id/:teamName/joinApply',
    service: '/gemini',
    version: '/v2',
  }),

  // 链接加入发起申请
  LINK_JOIN: getApi({
    path: '/team/id/:link/joinApply',
    service: '/gemini',
    version: '/v2',
  }),

  // 团队申请页面获取团队、审批信息
  TEAM_LINK_STATUS: getApi({ path: '/team/link/status' }),

  // 个人回收站列表
  PERSONAL_TRASH_LIST: getApi({ path: '/trash/list/personal' }),

  // 获取用户
  PROFILES: getApi({ path: '/ldaps/profiles' }),

  PERM: getApi({ path: '/resource/:resourceId/canDo' }),
  RESOURCE_PERM: getApi({ path: '/resource/:resourceId/perms' }),
  RESOURCE_TRASH_PERM: getApi({ path: '/trash/space/:spaceId/resource/:resourceId/perms' }),
  RESOURCE_SHARE_PERM: getApi({ path: '/share/:shareType/:shareId/resource/:resourceId/perms' }),
  DOC_DOWNLOAD: '/cooper_gateway/cooperation/v1/resource/:resourceId/downloadUrl',

  GET_DIRECTORY_AUTH_MEMBERS:
    '/cooper_gateway/adapter/v2/directory/auth/members',
  DIRECTORY_AUTH_MEMBERS: '/cooper_gateway/adapter/v1/directory/auth/members',
  DELETE_DIRECTORY_AUTH_MEMBERS:
    '/cooper_gateway/adapter/v1/resource/auth/members',
  FOLDAUTH_TEAM_MEMBERS:
    '/cooper_gateway/adapter/v1/team/:teamId/:resourceId/members',
  GET_USER_PERM_FOLDER:
    '/cooper_gateway/adapter/v2/team/:teamId/resource/:resourceId',
  GET_FOLDER_MEMBER:
    '/cooper_gateway/adapter/v2/directory/:resourceId/members',
  GET_FOLDER_INHERIT_STATUS:
    '/cooper_gateway/adapter/v1/resourceId/:resourceId/cooperationType',
  SEARCH_FOLDER_MEMBER:
    '/cooper_gateway/adapter/v2/directory/:resourceId/members',
  CHANGE_USER_PERM:
    '/cooper_gateway/adapter/v2/directory/:resourceId/member',
  CHANGE_CANCEL_PERM:
    '/cooper_gateway/adapter/v2/resource/member/cancel/perm/operation',
  CHANGE_USER_ROLE:
    '/cooper_gateway/adapter/v2/directory/:resourceId/member/role',
  CHANGE_USER_REMOVE:
    '/cooper_gateway/adapter/v2/directory/:resourceId/member',
  GET_COOPERATION_TYPE:
    '/cooper_gateway/adapter/v2/resourceId/:resourceId/cooperationType',
  GET_ALL_SHARE: '/cooper_gateway/adapter/v1/resource/:resourceId/allShare',
  FOLDER_PERM_ADD_MEMBER:
    '/cooper_gateway/adapter/v2/directory/:resourceId/members',
  FOLDER_PERM_ADD_MEMBER_LIST:
    '/cooper_gateway/adapter/v2/search/addDirectoryMember',

  // 校验私密链接分享的提取密码 or 获取连接分享目录下的资源
  SHARE_DIRECTORY:
    '/cooper_gateway/adapter/v2/shares/link/:shareLink/directory/:resourceId',
  // 添加团队成员搜索
  ADD_MEMBER_SEARCH: '/cooper_gateway/adapter/v2/search/addTeam',
  // 这个是多租户专用的添加人员搜索接口
  ADD_MEMBER_SEARCH_TENANT: '/cooper_gateway/adapter/v2/search/addTeamMember',

  SHARES_TO_ME_LIST: '/cooper_gateway/kaleido/v1/shareWithMe',
  SHARES_DIRECTORY:
    '/cooper_gateway/adapter/v1/shares/:shareId/directory/:resourceId',
  RESOURCES_PERMISSION: '/cooper_gateway/adapter/v1/resource/manage/meta',
  // 团队成员
  TEAM_MEMBERS: '/cooper_gateway/adapter/v2/team/:teamId/members',
  // TEAM_MEMBERS_BATCH: '/cooper_gateway/adapter/v2/team/:teamId/batch/members', 先保留 后续再真的删除
  TEAM_MEMBERS_BATCH: '/cooper_gateway/adapter/v2/team/:teamId/members',
  BATCH_ADD_TEAM_MEMBER: '/cooper_gateway/adapter/v2/team/members/ldap/batch',
  // 修改团队成员权限
  // MEMBER_PERMISSION: '/cooper_gateway/adapter/v1/team/:teamId/members/:id/permission',
  // 修改团队成员权限V2
  MEMBER_PERMISSION: '/cooper_gateway/adapter/v2/team/member',
  // 删除团队成员
  DELETE_TEAM_MEMBER: '/cooper_gateway/adapter/v2/team/:teamId/member',
  // 设置空间管理员
  // MEMBER_ROLE: '/cooper_gateway/adapter/v1/team/:teamId/members/:id/role',
  // 设置空间管理员
  MEMBER_ROLE: '/cooper_gateway/adapter/v2/team/member/role',

  // POST [8.1] 导出石墨文档
  DOWNLOAD_URL: '/cooper_gateway/cooperation/v1/resource/:id/downloadUrl',
  GET_DOWNLOAD_PROCESS: '/cooper_gateway/adapter/v1/resource/:id/download/progress',

  // 获取导入在线文档所需参数
  GET_IMPORT_PARMS: getApi({ path: '/doc/import/param', version: '/v1', service: '/adapter' }),

  // 创建石墨2导入任务
  CREATE_IMPORT_SHIMO2_TASK: getApi({ path: '/api/files/v1/import', version: '/v2', service: '/sdk' }),

  // 创建cooper导入任务
  CREATE_IMPORT_TASK: getApi({ path: '/doc/import/task', version: '/v1', service: '/adapter' }),

  // 查询导入进度
  GET_IMPORT_PROGRESS: getApi({ path: '/doc/import/task/progress', version: '/v1', service: '/adapter' }),
  RECENT_OPERATING: getApi({ path: '/resources/recent/operating' }),
  MOBILE_SEARCH: getApi({ path: '/mobile/directory/search' }),
  OPERATE_USER_SETTINGS: getApi({ path: '/user/setting', service: '/kaleido' }),

  // 团队空间首页公告
  SPACE_NOTICE: getApi({ path: '/space/notice/:spaceId' }),
  // 团队空间首页加锁
  SPACE_NOTICE_LOCK: getApi({ path: '/space/homeEditLock/fetch/:spaceId' }),
  // 团队空间首页解锁
  SPACE_NOTICE_UNLOCK: getApi({ path: '/space/homeEditLock/release/:spaceId' }),
  SPACE_SAFE_SUM: getApi({ path: '/spaces/:spaceId/resources/security/summary', version: '/v1', service: '/gemini' }),
  SPACE_LEVEL_FILE: getApi({ path: '/resource/details/:resourceId' }),
  TENANT_LOGIN_PUT: getApi({ path: '/tenant/:tenantId/selected', service: '' }),
  TENANT_LIST_GET: getApi({ path: '/tenant/user/tenants', service: '' }),
  USER_TENANT_INFO: getApi({ path: '/tenant/login/user/withTenants', version: '/v1', service: '' }),
  TENANT_CONFIG_GET: getApi({ path: '/tenancy/configs', version: '/v2' }),

  GET_COOPERATOR: '/cooper_gateway/cooperation/v1/resource/:resourceId/cooperator/detail',
  APPLY_MEMBER_PERM: '/cooper_gateway/cooperation/v1/resource/:resourceId/apply',
  OWNER_CHECK_APPLY_PERM: '/cooper_gateway/cooperation/v1/transfer/resource/:id/apply/owner/check',
  APPLY_OWNER_APPLY_PERM: '/cooper_gateway/cooperation/v1/transfer/resource/:id/apply/owner',
  COOPERATORS_PAGE: '/cooper_gateway/adapter/v2/resource/cooperators/page',
  GET_COOPERATION_ENTRY_PERM: '/cooper_gateway/adapter/v2/resource/member/operation',
  RESOURCE_LDAP: '/cooper_gateway/adapter/v2/document/:id/member',
  LDAP_PERMISSION: '/cooper_gateway/adapter/v2/document/:id/member',

  APPLY_NOTICE_UNREAD: getApi({ path: '/notice/:position/unread', version: '/v1', service: '/kaleido' }),
  APPLY_NOTICE_READ: getApi({ path: '/notice/:noticeId/read', version: '/v1', service: '/kaleido' }),
  TENANT_FEEDBACK: '/cooper_server/api/v1/feedback',
  SWITCH_TO_COOPER: process.env.APP_ENV === 'prod'
    ? 'https://cooper-lite.didichuxing.com/cooper_gateway/v1/downgrade/lite'
    : 'https://cooper-lite-qa.didichuxing.com/cooper_gateway/v1/downgrade/lite',
  ERROR_NOTICE: process.env.APP_ENV === 'prod'
    ? 'https://cooper-lite.didichuxing.com/cooper_gateway/v1/downgrade/cooper/notice'
    : 'https://cooper-lite-qa.didichuxing.com/cooper_gateway/v1/downgrade/cooper/notice',
};

