/**
 * 统一的Layout组件，根据路由和权限配置页面框架 Header、Aside
 */
import ErrorTips from '@/components/ErrorTipsDk';
import FileTreeModal from '@/components/FileTreeModal';
import Guide from '@/components/Guide';
import HaloNotification from '@/components/HaloNotification';
import Loading from '@/components/Loading';
import TransferModal from '@/components/TransferModal';
import UploadTips from '@/components/uploadTips';
import useGlobalInitDK from '@/hooks/useGlobalInitDK';
import useInitDKData from '@/hooks/useInitDKData';
// import classBind from 'classnames/bind';
import { intl } from 'di18n-react';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import Frame from './Frame';
import NoPermContent from './NoPermContent';
import LayoutContext from './layoutContext';

// 知识库layout 在这里添加是不是就可以
function Layout() {
  useGlobalInitDK();
  const [initLoading, setInitLoading] = useState(true);
  const [withoutPerm, setWithoutPerm] = useState();
  const { knowledgeId, pageId, shareId, templateId } = useParams();
  const { hasPerm, pageError, pageInfo, isDelete, hasPageEditPerm, setPageInfo } = useInitDKData({
    knowledgeId,
    pageId,
    shareId,
  });
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { teamId } = useParams();
  // 页面一共3个loading，无法衔接，在包含页面需求中跳过
  const isContainPageView = window.location.pathname.indexOf('knowledge/view') !== -1

  useEffect(() => {
    const reg = /^[\d|\.]*$/;
    if (pageId && !reg.test(pageId)) {
      navigate('/knowledge/errorPage');
    }
  }, [pathname]);

  useEffect(() => {
    // if (!isSet) return;
    if (!knowledgeId || shareId || pathname.indexOf('expire') !== -1) {
      setWithoutPerm(true);
      if (!isContainPageView) setInitLoading(false);
      return;
    }
    setWithoutPerm(false);
  }, [knowledgeId, shareId]);

  useEffect(() => {
    if (hasPerm && !isContainPageView) {
      setInitLoading(false);
    }
  }, [hasPerm]);

  useEffect(() => {
    if (pageError) {
      setInitLoading(false);
    }
  }, [pageError]);

  return (
    <LayoutContext.Provider
      value={{
        knowledgeId,
        pageId,
        shareId,
        templateId,
        pageInfo,
        isDelete,
        hasPageEditPerm,
        setPageInfo
      }}
    >
      {initLoading && !teamId && (
        <div className={'page-detail-loading'}>
          <Loading />
        </div>
      )}

      {(withoutPerm || hasPerm) && (
        <>
          <Frame>
            <Outlet />
          </Frame>
          <Guide />
          {withoutPerm === false && (
            <>
              <UploadTips />
              <FileTreeModal />
              <TransferModal />
              <HaloNotification />
            </>
          )}
        </>
      )}

      {withoutPerm === false && hasPerm === false && (
        <NoPermContent
          pageId={pageId}
          knowledgeId={knowledgeId}
          setInitLoading={setInitLoading}
        />
      )}

      {pageError && <ErrorTips title={intl.t('服务器错误，请稍后再试')} />}
    </LayoutContext.Provider>
  );
}

export default Layout;
