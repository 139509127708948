import { intl } from 'di18n-react';
import { useState, useRef, useEffect, useMemo } from 'react';
import { debounce } from 'lodash-es';
import { Input, Tooltip } from 'antd';
import classBind from 'classnames/bind';
import { getSearchDataDK, getRecentData } from '@/service/knowledge/search';
import SearchItem from '@/components/SearchItemDk';
import EmptyRender from '@/components/SpinRender';
import { getSystemType } from '@/utils';
import SearchOnScope from './SearchScope';
import { FileType } from '@/constants/cooper';
import DKCard from '@/pages/knowledge/MenHu/MyKnowledge/DKCard';
import styles from './style.module.less';

const cx = classBind.bind(styles);

const useUpdate = (fn, dep) => {
  const [isMount, setMount] = useState(false);
  useEffect(() => {
    isMount && fn();
  }, dep);
  useEffect(() => setMount(true), []);
};

const GlobalSearch = (props) => {
  const { openSearchModal, setClear } = props;
  const id = props.knowledge?.id;
  const isShare = props.knowledge?.isShare;
  const [data, setData] = useState({ metis: [], pages: [] });
  const [scope, setScope] = useState();
  const [value, setValue] = useState();
  const [historyData, setHistoryData] = useState([]);
  const [flag, setFlag] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [visible, setVisible] = useState(false);
  const fetchRef = useRef(0);
  const searchInput = useRef();
  const placeholderText = useMemo(() => {
    if (isShare) {
      return intl.t('搜索页面内容或文件');
    }
    if (!scope) {
      return intl.t('搜索页面及知识库');
    }
    return intl.t('搜索当前知识库页面');
  }, [scope, isShare]);

  useEffect(() => {
    // TODO:dc搜索用这个
    // if (!isDC()) {
    //   document.addEventListener('keydown', keydownFn);
    // }
    // return () => {
    //   !isDC() && document.removeEventListener('keydown', keydownFn);
    //   valueRef.current = null;
    //   searchInput.current = null;
    // };
    document.addEventListener('keydown', keydownFn);
    return () => {
      document.removeEventListener('keydown', keydownFn);
      // valueRef.current = null;
      searchInput.current = null;
    };
  }, [value]);

  const handleVisbleChange = (v) => {
    if (document.querySelector('#searchInput') === document.activeElement) {
      setVisible(true);
    } else {
      setVisible(v);
    }
    if (!v) {
      setFlag(false);
    }
  };

  const jumpTo = (metisId) => {
    window.open(`/knowledge/${metisId}/home`, '_blank');
    window.__OmegaEvent('ep_dkpc_dk_searchresult_dk_ck');
    afterClickItem();
  };

  const keydownFn = (event) => {
    // ctrl + J
    if (
      (event.ctrlKey && event.keyCode === 74)
      || (event.metaKey && event.keyCode === 74)
    ) {
      // windows默认键盘事件阻止
      event.preventDefault();
      event.returnValue = false;
      openAdvanceSearch();
    } else {
      event.returnValue = true;
    }
  };

  const afterClickItem = () => {
    setValue('');
    setVisible(false);
    searchInput.current.blur();
  };

  const openAdvanceSearch = () => {
    window.__OmegaEvent('ep_dkpc_dk_advancedsearch_ck');
    setVisible(false);
    openSearchModal(value);
  };

  const loadOptions = async (key) => {
    key = key?.trim() || '';
    fetchRef.current += 1;
    const fetchId = fetchRef.current;
    setData({ metis: [], pages: [] });
    if (!key) {
      setFetching(false);
      return;
    }
    setFetching(true);
    isShare && window.__OmegaEvent('ep_dkpc_sharebook_search_ck');
    let res = await getSearchDataDK({
      key,
      pageNum: 0,
      pageSize: 5,
      metisIds: scope,
      searchType: scope ? '4' : '0',
      isShare,
    });
    if (fetchId !== fetchRef.current) {
      return;
    }
    if (res) {
      res.pages = res?.allPages;
      setData(res);
    }
    setFetching(false);
  };

  const onSearch = useMemo(() => {
    return debounce(loadOptions, 800);
  }, [scope]);

  const getHistoryList = async () => {
    await getRecentData(isShare && { metisIds: id, isShare }).then((res) => {
      setHistoryData(new Proxy(res || [], {}));
      setData(new Proxy(res || [], {}));
    });
  };

  const onInputBlur = () => {
    !flag && setVisible(false);
  };

  useEffect(() => {
    if (visible) {
      value ? loadOptions(value) : getHistoryList();
      setFlag(true);
    }
  }, [visible]);

  useUpdate(async () => {
    if (!value) {
      setData({ metis: [], pages: [] });
    }
    setFetching(true);
    onSearch(value);
  }, [value]);

  useUpdate(() => {
    setScope(id);
  }, [id]);

  useEffect(() => {
    // getHistoryList();
    if (id) {
      setScope(id);
      // 忘记为啥要查了 感觉没用 先注释掉看看有没有啥问题
      // let res = await getDKPermission(id);
      // if (!res.perm) {
      //   setScope('')
      //   setClear(true);
      // } else {
      //   setScope(id)
      // }
    }
  }, []);

  const searchList = (
    <ul>
      {(data.pages?.items || []).map((d, index) => (
        <SearchItem
          key={index}
          config={{ tinyImg: true }}
          data={{
            ...d,
            dkShareType: FileType.DK_SHARE,
            shareLink: id,
          }}
          origin="ep_dkpc_dk_searchresult_page_ck"
          callback={afterClickItem}
          isShare={isShare}
        />
      ))}
      {!!data.metis?.items?.length && (
        <li className={cx('global-search-dk-title')}>{intl.t('知识库')}</li>
      )}
      <ul className={cx('search-metis-ul')}>
        {(data.metis?.items || []).map((d, index) => (
          <DKCard
            dkInfo={{
              ...d,
              id: d.metisId,
              picture: d.resourceTinyImage,
              relationTypeTags: d.relationTypeTags,
              name: d.resourceName,
              desc: d.metisDesc,
            }}
            key={index}
          />
        ))}
      </ul>
    </ul>
  );

  const historyList = (
    <>
      <p className={cx('search-history-title')}>{intl.t('最近浏览')}</p>
      <ul>
        {historyData?.map((d, index) => (
          <SearchItem
            key={index}
            config={{ tinyImg: true, description: false }}
            data={{
              ...d,
              dkShareType: FileType.DK_SHARE,
              shareLink: id,
            }}
            origin="ep_dkpc_dk_search_recent_ck"
            callback={afterClickItem}
            isShare={isShare}
          />
        ))}
      </ul>
    </>
  );

  const content = (
    <div
      className={cx('search-popover-content')}
      style={{ maxHeight: 'min(100vh - 180px, 646px)' }}
    >
      <EmptyRender loading={fetching} />
      {!fetching && value && searchList}
      {!fetching && !value && historyList}
      {!fetching && !value && historyData.length === 0 && (
        <div style={{ textAlign: 'center', padding: 4, color: '#bbb' }}>
          {intl.t('没有最近访问记录')}
        </div>
      )}

      {!fetching
        && value
        && data.pages?.items?.length + data.metis?.items?.length === 0 && (
          <div style={{ textAlign: 'center', padding: 4, color: '#bbb' }}>
            {intl.t('没有搜索到您想要的内容')}
          </div>
      )}

      {isShare ? (
        <div className={cx('search-more-wrap')}>
          <span className={cx('click-tip')}>
            {intl.t('点击可定位到相关内容')}
          </span>
        </div>
      ) : (
        <div className={cx('search-more-wrap')}>
          <div
            onClick={() => {
              window.__OmegaEvent('ep_dkpc_dk_search_recent_viewmore_ck');
              openAdvanceSearch();
            }}
            className={cx('search-more')}
          >
            <span>
              <i
                className={`${cx('dk-icon-sousuo', 'dk-iconfont')}`}
                style={{ color: '#bbb' }}
              />
              {intl.t('查看更多')}

              <i
                className={`search-more-icon ${cx(
                  'dk-icon-youjiantou1',
                  'dk-iconfont',
                )}`}
              />
            </span>
            <span>{intl.t('enter回车可快速查看')}</span>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <>
      <div className={cx('search-wrap')}>
        <Tooltip
          title={content}
          visible={visible}
          trigger="click"
          overlayClassName={cx('global-search-popover', {
            'share-search-popover': isShare,
          })}
          autoAdjustOverflow={false}
          placement={isShare ? 'bottomRight' : 'bottom'}
          onVisibleChange={handleVisbleChange}
        >
          <Input
            size="large"
            id="searchInput"
            placeholder={placeholderText}
            allowClear
            value={value}
            ref={searchInput}
            className={cx('search-input')}
            bordered={false}
            onClick={() => {
              setVisible(true);
            }}
            onBlur={onInputBlur}
            onChange={(v) => {
              setValue(v.target.value);
            }}
            onPressEnter={() => !isShare && openAdvanceSearch()}
            addonBefore={
              <SearchOnScope
                value={scope}
                isShare={isShare}
                clearScope={(v) => {
                  setClear(true);
                  setScope(v);
                  setVisible(false);
                }}
              />
            }
            addonAfter={
              !isShare && (
                <span
                  onClick={openAdvanceSearch}
                  className={cx('search-icon-btn')}
                >
                  <span className={cx('search-text')}>
                    {intl.t('高级搜索')}
                  </span>
                  {getSystemType() === 'mac' ? '⌘+J' : 'ctrl+J'}{' '}
                </span>
              )
            }
          />
        </Tooltip>
      </div>
    </>
  );
};

export default GlobalSearch;
