import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// 处理LayoutDk中的公共逻辑
export default () => {
  const dispatch = useDispatch();
  const { getGlobalOutsideChain } = dispatch.CooperIndex;
  const { globalOutsideChain } = useSelector((state) => state.CooperIndex);

  useEffect(() => {
    if (JSON.stringify(globalOutsideChain) === '{}') {
      setTimeout(() => {
        getGlobalOutsideChain();
      }, 2000);
    }
  }, [globalOutsideChain]);
};
