import CooperApi from '@/utils/request/api/CooperApi';
import { get, post, del, put } from '@/utils/request/cooper';

export async function putTenantLoginInfo(tenantId) {
  return put(CooperApi.TENANT_LOGIN_PUT.replace(':tenantId', tenantId));
}

export async function getTenantList(params) {
  let startTime = new Date().getTime();
  let res = await get(CooperApi.TENANT_LIST_GET, { params });
  let endTime = new Date().getTime();
  window.__OmegaEvent('tech_ep_network_load_time', '知识库核心接口加载时长', {
    source: 'tenants',
    time: endTime - startTime,
  })
  return res;
}

export async function getTenantConfig(params) {
  return get(CooperApi.TENANT_CONFIG_GET, { params });
}

export async function getCombineTenantInfo(params) {
  let startTime = new Date().getTime();
  let res = await get(CooperApi.USER_TENANT_INFO, { params });
  let endTime = new Date().getTime();
  window.__OmegaEvent('tech_ep_network_load_time', '知识库核心接口加载时长', {
    source: 'withtenants',
    time: endTime - startTime,
  })
  return res;
}