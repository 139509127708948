import { intl } from 'di18n-react';
import { useEffect, useState, useContext, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Tree, Spin, message } from 'antd';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import NotificationStatus from '@/constants/notification';
import useNotification from '@/hooks/useNotification';
import classBind from 'classnames/bind';
import OutlinedIcon from './OutlinedIcon';
import useTreeData from './UseTreeData';
import { starAction } from '@/service/knowledge/star';
import * as service from '@/service/knowledge/pageTree';
import * as servicePage from '@/service/knowledge/page';
import { checkNameErrorMsg } from '@/utils';
import { setLocalData, getLocalData } from '@/utils/localStorage';
import { renameDoc } from '@/service/knowledge/didoc';
import stylesCommon from '@/components/serviceComponents/AsideDK/catalogCommon.module.less';
import { DocType } from '@/constants/page';
import { reportMeasure } from '@/utils/performance';
import TreeNode from './TreeNode';

const cm = classBind.bind(stylesCommon);

export const formatNode = ({
  pageId,
  pageName,
  hasChild,
  parentId,
  fullPath,
  externalSystemType,
}) => {
  return {
    key: pageId,
    isLeaf: !hasChild,
    title: pageName,
    parentId,
    pageId,
    pageName,
    fullPath,
    externalSystemType,
  };
};

function CatalogTree({ isOpenCatalog, height, isResizing }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { knowledgeId, pageId: currPageId } = useContext(LayoutContext);
  const { previewFileId, docInfo } = useSelector((state) => state.pageDetail);
  const { latestVersion, multiPath = [] } = docInfo;
  const [selectedKey, setSelectedKey] = useState('');
  const notification = useNotification();
  const view = useSelector((state) => state.pageDetail.view);
  const treeFn = useTreeData(formatNode);
  const {
    treeData,
    setData,
    addNode,
    deleteNode,
    updateNode,
    moveNode,
    getNodeByKey,
    getChildKeysById,
  } = treeFn;
  const [loading, setLoading] = useState(true);
  const [loadedKeys, setLoadedKeys] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [isForbidLoad, setIsForbidLoad] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  // 以下ref都是临时处理方案，后续要治理一下这部分的变量作用域
  const expandedKeysRef = useRef([]); // 删除时使用，方法体里拿不到最新state，使用ref
  const loadedKeysRef = useRef([]); // 删除时使用
  const moveRef = useRef(); // 外部移动时使用
  const paramsInfo = useRef({ docInfo, view }); // 外部移动时使用
  const treeRef = useRef();
  const { setTreeFn } = dispatch.PageTree;
  const { changeDocInfoInName, changeStar } = dispatch.pageDetail;
  const { teamId } = useParams();

  const getData = (key, type = 1) => {
    return service.getPageTreeData({
      pageId: key,
      type,
      knowledgeId,
    });
  };

  useEffect(() => {
    /* let localData = getLocalData(`hand:${knowledgeId}`);
    if (localData.length > 0) {
      setData(localData ?? []);
      setLoading(false);
      reportMeasure({
        startName: 'page-start',
        endName: 'catalogTree-end-local',
        measureName: 'catalogTree-initLoad-local',
      })
    }
    */
    initData();
  }, [knowledgeId]);

  const initData = async () => {
    try {
      const res = await getData(currPageId, 0);
      setData(res);
      setLocalData(`hand:${knowledgeId}`, res);
      setIsForbidLoad(false);

      treeRef.current.scrollTo({ key: currPageId, align: 'top', offset: 10 }); // 页面一进来滚动到选中的节点位置
    } catch (error) {
      const errorCodeList = [300002, 501082, 301052]; // 资源不存在 // 无权限 // 移动后资源位置不对
      if (errorCodeList.includes(error.errorCode)) {
        const res = await getData('0', 0);
        navigate(`/knowledge/${knowledgeId}/home`);
        setData(res);
        setLocalData(`hand:${knowledgeId}`, res);
      }
    } finally {
      setLoading(false);
      reportMeasure({
        startName: 'page-start',
        endName: 'catalogTree-end-req',
        measureName: 'catalogTree-initLoad-req',
      });
    }
  };

  useEffect(() => {
    if (multiPath.length) {
      setExpandedKeys([
        ...new Set([...expandedKeys, ...multiPath.map((v) => v.id)]),
      ]);
    }
  }, [multiPath]);

  useEffect(() => {
    setExpandedKeys([...new Set([...expandedKeys])]);
  }, [treeData]);

  useEffect(() => {
    paramsInfo.current = { docInfo, view, previewFileId };
  }, [docInfo, view, previewFileId]);

  useEffect(() => {
    if (!isOpenCatalog) {
      setExpandedKeys([]);
    }
  }, [isOpenCatalog]);

  useEffect(() => {
    setSelectedKey(currPageId);
  }, [currPageId]);
  useEffect(() => {
    expandedKeysRef.current = expandedKeys;
  }, [expandedKeys]);

  // 由于节点设置了阻止冒泡，当前函数不执行，逻辑需要写在<TreeNode/>中
  const onSelect = () => {};

  const onExpand = (v) => {
    setExpandedKeys(v);
    window.performance.mark(`catalog-expand-start${v.slice(-1)?.[0]}`);
    window.__OmegaEvent('ep_dkpc_page_xialaicon_ck');
  };

  const onLoadData = async ({ key, children }) => {
    if (children.length) {
      return;
    }
    performance.mark(`catalog-expand-start${key}`)
    try {
      let res = await getData(key);
      updateNode(key, { children: res });
      reportMeasure({
        startName: `catalog-expand-start${key}`,
        endName: `catalog-expand-end${key}`,
        measureName: 'catalog-expand-load',
      });
    } catch (error) {
      if (error.errorCode === 300002 || error.errorCode === 501082) {
        // 资源不存在 // 无权限
        const res = await getData('0', 0);
        navigate(`/knowledge/${knowledgeId}/home`);
        setData(res);
        setLocalData(`hand:${knowledgeId}`, res);
      }
    }
  };

  const onLoad = (keys) => {
    if (moveRef.current) return;
    setLoadedKeys(keys);
    loadedKeysRef.current = keys;
    moveRef.current = false;
  };

  /**
   * 几种case:
   * 1.同级之间移动
   *    移动到首尾
   *        移动到首尾，preId不对，应该是'0'
   *    移动到有子节点的节点的下级
   *        该节点收起并且加载过子页面
   *        该节点收起并且未加载过子页面
   *        该节点展开
   * 2.移动到目标节点子级
   *    目标节点：
   *        目标节点展开时
   *        目标节点收起并且未加载过子页面
   *        目标节点收起并且加载过子页面
   *        目标节点为叶子节点
   *    移动节点：
   *        移动节点是叶子节点
   *        移动节点父级只有一个元素
   *        移动节点父级有多个元素
   * 3.连续移动
   *
   */

  const onDrop = async (info) => {
    const { dragNode, dropToGap, node, dropPosition } = info;
    let start = dragNode.key; // origainNode
    let end = node.key; // targetNode
    let isChild = !dropToGap; // 如果是子节点为false,不是就是true

    window.performance.mark(`catalog-drop-start${start}`);

    try {
      const { pageName, fullPath } = await service.moveCurrPage({
        knowledgeId,
        toPrePageId: isChild ? '0' : dropPosition > -1 ? end : '0',
        toParentPageId: isChild ? end : node.parentId,
        pageId: start,
      });

      // 拖拽之后元素的权限会变化，因此重新获取pageDetail
      if (docInfo.pageId === start) {
        const { resetPageDetail } = dispatch.pageDetail;
        resetPageDetail({ pageId: start });
      }
      dragNode.pageName = pageName
      dragNode.title = pageName;
      // 兼容一下后端的字段，move之后需要更新fullPath
      if (fullPath) {
        dragNode.fullPath = fullPath;
      }
      if (dropPosition === -1) {
        // 整个树的第一个节点, 这个是相对于第一个节点的位置
        moveNode(
          { key: dragNode.key, node: dragNode },
          { parentKey: '0', position: 0 },
        );
        return;
      }
      if (isChild) {
        // 插入成为某个节点的子节点
        const needFetchChildren = !node.isLeaf && !node.children.length;
        setExpandedKeys([...new Set([...expandedKeys, end])]);
        if (needFetchChildren) {
          deleteNode(dragNode.key);
          /*  let res = await getData(end);
          updateNode(end, { children: res }); */
        } else {
          moveNode(
            { key: dragNode.key, node: dragNode },
            { parentNode: node, position: 0 },
          );
        }
      } else {
        // 成为某个节点的兄弟节点
        moveNode({ key: dragNode.key, node: dragNode }, { preNode: node });
      }
      reportMeasure({
        startName: `catalog-drop-start${start}`,
        endName: `catalog-drop-end${start}`,
        measureName: 'catalog-drop-time',
      });
      if (dragNode.parentId === (isChild ? end : node.parentId)) {
        notification(NotificationStatus.SUCCESS, intl.t('移动成功'));
      } else {
        notification(
          NotificationStatus.SUCCESS,
          intl.t('移动成功，已继承{slot0}的成员及权限', {
            slot0: node.parentId === '0' && !isChild ? intl.t('知识库新的') : intl.t('新父级页面'),
          }),
        );
      }
      window.__OmegaEvent('ep_dkpc_page_dragmove_ck');
    } catch (e) {}
  };

  const onDragOver = () => {
    const overNode = document.querySelector('.drag-over');

    if (overNode && overNode.classList.contains('ant-tree-treenode')) {
      overNode.classList.add('drop-container');
    }
  };

  const onDragStart = (e) => {
    const originNode = e.event.target;
    const { dataTransfer } = e.event;
    dataTransfer.setDragImage(originNode, 20, 40); // 拖拽中弹窗距离鼠标的位置
    dataTransfer.setData('livechatDocInfo', JSON.stringify({ resourceId: e.node.pageId, title: e.node.title }))
    setTimeout(() => {
      originNode.classList.add('node-dragging');
    }, 300);
    setIsDragging(true);
  };

  const onDragEnd = (e) => {
    const originNode = e.event.target;
    setTimeout(() => {
      originNode.classList.remove('node-dragging');
    }, 300);
    setIsDragging(false);
  };

  /**
   * 几种case:
   * 1.叶子节点
   * 2.父节点展开状态
   * 3.父节点收起状态已加载子节点
   * 4.父节点收起状态未加载子节点
   *
   */
  const handleAdd = async (parentId) => {
    setExpandedKeys([...new Set([...expandedKeys, parentId])]);
    const nodeObj = getNodeByKey(parentId);

    const node = await service.createPage({ parentId, knowledgeId });
    if (!nodeObj.isLeaf && nodeObj.children.length === 0) {
      let res = await getData(parentId);
      updateNode(parentId, { children: res });
    } else {
      addNode(parentId, node);
    }
    navigate(
      teamId
        ? `/team-file/${teamId}/knowledge/${knowledgeId}/${node.pageId}/edit`
        : `/knowledge/${knowledgeId}/${node.pageId}/edit`,
    );
    window.__OmegaEvent('ep_dkpc_createpage_ck');
  };

  const handleAddExcel = async (parentId) => {
    setExpandedKeys([...new Set([...expandedKeys, parentId])]);
    const nodeObj = getNodeByKey(parentId);

    const node = await service.createExcel({ parentId, knowledgeId });
    if (!nodeObj.isLeaf && nodeObj.children.length === 0) {
      let res = await getData(parentId);
      updateNode(parentId, { children: res });
    } else {
      addNode(parentId, node);
    }
    navigate(
      teamId
        ? `/team-file/${teamId}/knowledge/${knowledgeId}/${node.pageId}/edit`
        : `/knowledge/${knowledgeId}/${node.pageId}/edit`,
    );
    window.__OmegaEvent('ep_dkpc_createpage_ck');
  };

  /**
   * 几种case:
   * 1.节点switchicon变化
   *      叶子节点
   *      父节点只有一个子节点
   *      父节点有多个子节点
   * 2.跳转地址(TODO)
   *     删除操作后当前节点存在：不变
   *     删除操作后当前节点不存在：向上找父节点，没直到根节点 或者跳转到首页
   *   跳转逻辑（目前）
   *      存在父节点，跳转到父节点，不存在时跳转到首页
   *
   */
  const handleDelete = async (key, parentId, nodeParent = true) => {
    // nodeParent 是来区分只执行前端渲染层面的节点删除还是需要请求删除
    if (nodeParent) {
      await service.deleteCurrPage({ pageId: key, knowledgeId });
    }
    let childKeys = getChildKeysById(key);
    deleteNode(key);
    setLoadedKeys(loadedKeysRef.current.filter((v) => !childKeys.includes(v)));
    /**
     * TODO:删除逻辑
     * 如果删除操作不会删除当前选中的页面，保持当前选中态不变
     * 如果当前选中页被删除了就依次找操作「删除」页面的父级、首页
     */
    if (nodeParent) {
      setExpandedKeys(
        expandedKeysRef.current.filter((v) => !childKeys.includes(v)),
      );
      notification(NotificationStatus.SUCCESS, intl.t('删除成功'));
      if (parentId !== '0') {
        setTimeout(() => {
          setSelectedKey(parentId);
          navigate(
            teamId
              ? `/team-file/${teamId}/knowledge/${knowledgeId}/${parentId}`
              : `/knowledge/${knowledgeId}/${parentId}`,
          )
          // navigate(`/knowledge/${knowledgeId}/${parentId}`);
        }, 50);
      } else {
        navigate(
          teamId
            ? `/team-file/${teamId}/knowledge/${knowledgeId}/home`
            : `/knowledge/${knowledgeId}/home`,
        )
        // navigate(`/knowledge/${knowledgeId}/home`);
      }
    }
  };

  const handleUpdate = async (key, newName, type, serviceUpdate = true) => {
    const { changeTitle, changePreviewFileName } = dispatch.pageDetail;

    if (serviceUpdate) {
      let errorMsg = checkNameErrorMsg(newName);
      if (errorMsg.length > 0) {
        notification(NotificationStatus.ERROR, errorMsg);
        return;
      }
      await service.renameCurrPage({ pageId: key, name: newName.trim() });
      updateNode(key, { title: newName.trim(), pageName: newName.trim() });
    }
    changeTitle({ name: newName.trim(), pageId: key });
    const {
      docInfo: newDocInfo = {},
      view: viewRef,
      previewFileId: previewFileIdRef,
    } = paramsInfo.current;
    if (
      type === DocType.DK_FILE
      && (previewFileIdRef.inEdit === previewFileIdRef.inDetail
        || latestVersion === null)
    ) {
      changePreviewFileName(newName.trim());
    }

    if (key === newDocInfo.pageId) {
      viewRef.updateTitle && viewRef.updateTitle(newName.trim());
      // 分享弹窗中的页面名称使用的docInfo的name
      changeDocInfoInName({
        ...newDocInfo,
        name: newName.trim(),
        pageName: newName.trim(),
      });
    } else {
      // 修改草稿中的标题
      const pageInfo = await servicePage.getPageDetail({ pageId: key });
      renameDoc({ title: newName.trim(), docId: pageInfo.guid });
    }
  };

  const handleCopy = async (pageId, parentId) => {
    message.loading({
      content: intl.t('创建中...'),
      key: pageId,
      duration: 10,
    });
    try {
      await service.copyPage({
        pageIds: [pageId],
        toParentPageId: parentId,
        knowledgeId,
        pageStatus: 1,
      });
    } catch (error) {
      message.error({
        content: intl.t('副本创建失败,您没有权限'),
        key: pageId,
        duration: 2,
      });
    }
  };

  const handleMove = (node) => {
    // 提供给外部使用，用于跨库，可能出现同库见移动 移出 移入
    moveRef.current = true;
    const originNode = getNodeByKey(node.pageId);
    let nodeType;
    if (originNode) {
      nodeType = originNode.type;
      handleDelete(node.pageId, originNode.parentId, false);
    }
    const fullPath = (node.path || []).map((v) => `${v}`);
    // 被迁移的页面是当前页面或者当前页面的父级页面
    const pageMultiPath = paramsInfo.current?.docInfo?.multiPath || [];
    const pathIds = pageMultiPath.map((v) => v.id);
    if (pathIds.includes(node.pageId)) {
      setExpandedKeys([...new Set([...expandedKeys, ...fullPath, currPageId])]);
    }
    const parentNode = getNodeByKey(node.parentId);
    if (
      parentNode
      && (parentNode.isLeaf
        || (!parentNode.isLeaf && parentNode.children.length)
        || parentNode.key === '0')
    ) {
      addNode(node.parentId, node, parentNode.children.length);
      if (originNode.pageName !== node.pageName) {
        handleUpdate(node.pageId, node.pageName, nodeType || node.type, false);
      }
    }
  };

  const handleStar = async (pageId, parentId, nodeData) => {
    const { markedStar } = nodeData;
    const type = markedStar ? 0 : 1;
    const listInfo = {
      resourceId: pageId,
      starType: 'RESOURCE', // 收藏类型  RESOURCE普通页面及资源; PAGE_SHARE页面分享; DK_SHARE知识库分享【必填】
      starInfo: '',
      appId: 4,
    };
    let data = await starAction(type, [listInfo]);
    if (data) {
      message.success(type === 1 ? intl.t('收藏成功') : intl.t('取消收藏成功'));
      updateNode(pageId, { markedStar: !markedStar });
      changeStar(!markedStar);
    }
  };

  useEffect(() => {
    setTreeFn({ ...treeFn, movePage: handleMove });
  }, []);
  return (
    <div
      className={`${cm({
        'catalog-page-wrap': true,
      })}`}
    >
      {
        <>
          {loading && (
            <div className={cm('aside-catalog-loading')}>
              <Spin />
            </div>
          )}

          {!loading && treeData.length > 0 && (
            <Tree
              ref={treeRef}
              draggable={
                true ? { icon: false, nodeDraggable: () => true } : false
              }
              loadedKeys={loadedKeys}
              className="draggable-tree"
              expandedKeys={expandedKeys}
              selectedKeys={[selectedKey]}
              treeData={treeData}
              onSelect={onSelect}
              onExpand={onExpand}
              onDrop={onDrop}
              onLoad={onLoad}
              onDragOver={onDragOver}
              onDragStart={onDragStart}
              onDragEnd={onDragEnd}
              // height={height}
              blockNode
              loadData={onLoadData}
              switcherIcon={<OutlinedIcon />}
              titleRender={(nodeData) => (
                <TreeNode
                  nodeData={nodeData}
                  isDragging={isDragging || isResizing}
                  doCreateSubPage={handleAdd}
                  doCreateSubExcel={handleAddExcel}
                  doDelete={handleDelete}
                  doRename={handleUpdate}
                  doCopy={handleCopy}
                  doStar={handleStar}
                />
              )}
            />
          )}

          {!loading && treeData.length === 0 && (
            <div className={cm('empty-tree')}>
              {intl.t('没有页面哦，新建页面试试吧~')}
            </div>
          )}
        </>
      }
    </div>
  );
}

export default CatalogTree;
