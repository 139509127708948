import WhiteScreenError, { withWhiteScreenError } from '@didi/whitescreen-error';
import { getUserNameFromCookie, openNewWindow } from '@/utils';
import { intl } from 'di18n-react';
import './style.less';

const fallbackFunc = () => {
  return (
    <div className='white-screen-container'>
      <div className='text'>{intl.t('当前模块发生异常错误，请重新刷新页面，或联系')}</div>
      <a
        className='link'
        target='_blank'
        href='https://im.xiaojukeji.com/channel?uid=179964&token=3bdb88d2caad6b9ef23b726c9c11a837&id=146847933529642240'
      >
        Cooper 反馈群【Feedback】
      </a>
    </div>
  );
};

// 白屏组件配置信息
const whiteErrorConfig = {
  env: process.env.APP_ENV, // 默认prod,线上环境请使用'prod',便于数据统计
  ldap: getUserNameFromCookie(), // 用户ldap
  fallback: fallbackFunc, // 降级UI
  omegaInstance: window.Omega, // omega实例,若未传入会取window.Omega
  dcWebhook: [
    'https://im-dichat.xiaojukeji.com/api/hooks/incoming/700c668f-fd6b-479a-a724-e98260232c8e',
  ], // 群机器人hook 可不传，并且在Naga平台上动态配置报警群，详情见下
  // onError: (error, componentStack) => {
  isReport: process.env.APP_ENV === 'prod',
  // }, // 发生错误时事件
  onReset: () => {
    window.location.reload();
  }, // 重置组件时事件
};


// 两种使用方式
const ErrorBoundary = ({ children }) => {
  return (
    <WhiteScreenError {...whiteErrorConfig}>
      {children}
    </WhiteScreenError>
  );
}

export default ErrorBoundary;
// // or
// const WhiteScreenErrorExample = withWhiteScreenError(ExampleComponent, whiteErrorConfig)

