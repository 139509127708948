import { intl } from 'di18n-react';
import { useState, useContext, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'antd';
import classBind from 'classnames/bind';
import { OPERATE_CONTENT_DATA } from '@/constants/catalog';
import stylesCommon from '@/components/serviceComponents/AsideDK/catalogCommon.module.less';
import NotificationStatus from '@/constants/notification';
import { getPagePermission } from '@/service/knowledge/page';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import useNotification from '@/hooks/useNotification';
import OperatePopover from '../OperatePopover';
import AddPopover from '../AddPopover';
import styles from './style.module.less';
import htmlsvg from './html.svg';

const cx = classBind.bind(styles);
const cm = classBind.bind(stylesCommon);

function TreeNode({
  nodeData,
  doCreateSubPage,
  doCreateSubExcel,
  doDelete,
  doRename,
  doCopy,
  doStar,
  isDragging,
}) {
  const { pageId, knowledgeId, setPageInfo } = useContext(LayoutContext);
  const notification = useNotification();
  const getpermRef = useRef();
  const { teamId } = useParams();
  const navigate = useNavigate();

  const [pagePerm, setPagePerm] = useState();
  const [operateStep, setOperateStep] = useState(
    OPERATE_CONTENT_DATA.hiddenStep,
  );

  const handleCancelPermReq = () => {
    let now = new Date().getTime();
    if (getpermRef.current?.time && now - getpermRef.current?.time < 200) {
      clearTimeout(getpermRef.current.settime);
    }
  };

  const handleSubHover = () => {
    if (!pagePerm) {
      let time = new Date().getTime();
      let settime = setTimeout(getPagePerm, 200);
      getpermRef.current = { time, settime };
    }
  };

  const handleHoverLeave = () => {
    handleCancelPermReq();
  };

  const clickPage = (e, node) => {
    const { pageId: currPageId } = node;

    if (currPageId === pageId) {
      return;
    }
    setPageInfo({});

    window.__OmegaEvent('ep_dkpc_pageview_ck');

    if (window.location.pathname.endsWith('/edit') && currPageId !== pageId) {
      notification(
        NotificationStatus.SUCCESS,
        intl.t('你的修改已自动保存于编辑态中'),
      );
    }

    navigate(
      teamId
        ? `/team-file/${teamId}/knowledge/${knowledgeId}/${currPageId}`
        : `/knowledge/${knowledgeId}/${currPageId}`
    )
  };

  const getPagePerm = async () => {
    let { perm } = await getPagePermission({ pageId: nodeData.pageId });
    setPagePerm(perm);
  };
  const getPrefixIcon = () => {
    if (nodeData.externalSystemType === 'html') return <img src={htmlsvg} />;
    return null;
  }
  return (
    <span
      className={`${cx('tree-node-item-wrap')} catalog-tree-node tree-node-${
        nodeData.pageId
      }`}
      onMouseEnter={() => handleSubHover(nodeData.pageId)}
      onMouseLeave={() => handleHoverLeave(nodeData.pageId)}
    >
      <a
        draggable={false}
        onClick={(e) => {
          clickPage(e, nodeData);
        }}
        className={cx('item-name')}
      >
        <span
          className={cx('item-name-link')}
          key={nodeData.key}
        >
          {nodeData.title}
        </span>
      </a>
      {
        !isDragging && (
          <span
            className={cx('operate-wrap')}
            onClick={(e) => e.stopPropagation()}
          >
            <OperatePopover
              pageId={nodeData.pageId}
              operateStep={operateStep}
              doDelete={() => {
                doDelete(nodeData.pageId, nodeData.parentId, nodeData);
              }}
              doCopy={() => {
                doCopy(nodeData.pageId, nodeData.parentId);
              }}
              doStar={() => {
                doStar(nodeData.pageId, nodeData.parentId, nodeData);
              }}
              fullPath={nodeData.fullPath}
              pagePerm={pagePerm}
              parentId={nodeData.pageId}
              setOperateStep={setOperateStep}
              doRename={(newName) => {
                doRename(nodeData.pageId, newName, nodeData.type);
              }}
              pageName={nodeData.title}
              type={nodeData.type}
              nodeData={nodeData}
              // getPopupContainer={() => document.querySelector(`.tree-node-${pageId}`)}
            >
              <Tooltip
                title={intl.t('重命名、创建副本、移动到、收藏、删除')}
                mouseLeaveDelay={0.01}
                overlayClassName={cx(
                  'dk-ant-tooltip__reset',
                  'catalog-operate-tooltip',
                )}
              >
                <i
                  className={`${cx('dk-icon-gengduo', 'dk-iconfont')}
                    ${cm('operation-common-icon', 'icon-left')}`}
                />
              </Tooltip>
            </OperatePopover>

            <AddPopover
              pagePerm={pagePerm}
              pageName={nodeData.title}
              pageId={nodeData.pageId}
              fullPath={nodeData.fullPath}
              doCreateSubPage={doCreateSubPage}
              doCreateSubExcel={doCreateSubExcel}
            >
              <Tooltip
                title={intl.t('添加子页面、导入、上传本地文件')}
                mouseLeaveDelay={0.01}
                overlayClassName={cx(
                  'dk-ant-tooltip__reset',
                  'catalog-operate-tooltip',
                )}
              >
                <i
                  className={`${cx('dk-icon-tianjia', 'dk-iconfont')} ${cm(
                    'operation-common-icon',
                  )}`}
                />
              </Tooltip>
            </AddPopover>
          </span>
        )
      }
    </span>
  );
}

export default TreeNode;
